import React from 'react';
import Button from '../Button';
import { connect } from 'react-redux';
import {setMenu} from "../../redux/actions/MenuActions";
import StyleGuide from "../../../res/constants/StyleGuide";

function MenuButton(props) {
    const translations = props.translations[props.translations.currentLanguage];

    return (
        <Button onPress={props.onPress ? props.onPress : () => {
            props.setMenu({showMenu: false});
        }} label={translations.header.goBack} wrapperStyle={styles.buttonWrapper} style={styles.buttonText} />
    );
}

const styles = {
    buttonWrapper: {
        width: 30 + '%',
        height: StyleGuide.buttons.height,
    },
    buttonText: {
        fontSize: StyleGuide.buttons.fontSize,
    },
};

const mapStateToProps = state => {
    return {
        translations: state.TranslateReducer,
        menuState: state.MenuReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMenu: (payload) => dispatch(setMenu(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuButton);
