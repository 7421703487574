import React from 'react';
import { Dimensions, View, Platform } from 'react-native';

const platformSpecifixStyles = Platform.select({
    ios: {
        headerMarginTop: 40,
    },
    android: {
        headerMarginTop: 40,
    },
    web: {
        headerMarginTop: 40,
    },
})

export default function Header(props) {
    return (
        <View style={{...styles.headerWrapper, ...props.style}}>
            {props.headerLeft ? props.headerLeft() : <></>}
            {props.headerCenter ? props.headerCenter() : <></>}
            {props.headerRight ? props.headerRight() : <></>}
        </View>
    );
}

const styles = {
    headerWrapper: {
        width: Dimensions.get('window').width,
        marginTop: platformSpecifixStyles.headerMarginTop,
        marginBottom: 20,
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
};
