import React, {useState} from 'react';
import {Dimensions, Image, ScrollView, Switch, Text, View} from 'react-native';
import Header from "../components/Header";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import MenuButton from "../components/HeaderButtons/MenuButton";
import RNListSlider from 'react-native-list-slider';
import AutoHeightImage from 'react-native-auto-height-image';
import Space from "../components/Space";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import * as firebase from "firebase";
import {setUser} from "../redux/actions/UserActions";
import {connect} from 'react-redux';
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";

function Measure(props) {
    const translations = props.translate[props.translate.currentLanguage];
    const [heat, setHeat] = useState(2);
    const [step, setStep] = useState(1);
    const [unit, setUnit] = useState(true);
    const [hasOver375, setHasOver375] = useState(false);
    const [hasUnder355, setHasUnder355] = useState(false);
    const [hasUnder345, setHasUnder345] = useState(false);
    const [answers, setAnswers] = useState({
        step1: 0,
        step2: 0,
        step3: 0,
        step4: 0,
        step5: 0,
    });
    const pointNames = {
        step1: translations.points.pet.tongue,
        step2: translations.points.pet.frontRightPaw,
        step3: translations.points.pet.frontLeftPaw,
        step4: translations.points.pet.backRightPaw,
        step5: translations.points.pet.backLeftPaw,
    };

    const pointerWidth = (Dimensions.get('window').width - 50) * 0.13;
    const imageWidth = Dimensions.get('window').width - 50;
    const pointerPositions = {
        step1: {
            left: -(imageWidth / 2) + ((imageWidth * .510)),
            top: (imageWidth * .780) + pointerWidth,
        },
        step2: {
            left: -(imageWidth / 2) + ((imageWidth * .260)),
            top: (imageWidth * .216) + pointerWidth,
        },
        step3: {
            left: -(imageWidth / 2) + ((imageWidth * .740)),
            top: (imageWidth * .370) + pointerWidth,
        },
        step4: {
            left: -(imageWidth / 2) + ((imageWidth * .260)),
            top: (imageWidth * .60) + pointerWidth,
        },
        step5: {
            left: -(imageWidth / 2) + ((imageWidth * .740)),
            top: (imageWidth * .760) + pointerWidth,
        }
    };

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton realBack={true} />)}
                headerCenter={() => (<HeaderLogo/>)}
                headerRight={() => (<MenuButton/>)}
            />
            <Image source={require('../../res/images/blue_pointer.gif')} style={{
                zIndex: 1,
                left: pointerPositions["step" + step].left,
                top: pointerPositions["step" + step].top,
                width: pointerWidth,
                height: pointerWidth,
            }}/>
            {
                step == 1 ?
                    <>
                        <AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/pet-steps/head.png')} width={imageWidth}/>
                        <Text style={{...styles.text, marginBottom: 15, paddingHorizontal: 10}}>
                            {translations.measure.petTongue}
                        </Text>
                    </>
                :
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/pet-steps/paws.png')} width={imageWidth}/>)
            }
            <Text style={{...styles.text, marginBottom: 15,}}>{pointNames["step" + step]}</Text>
            <Text style={styles.text}>{translations.measure.instructions}</Text>
            <Space/>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Text style={styles.unitText}>°F</Text>
                <Switch
                    trackColor={{false: '#d3d3d3', true: '#d3d3d3'}}
                    thumbColor={Colors.primary}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={setUnit}
                    value={unit}
                />
                <Text style={styles.unitText}>°C</Text>
            </View>
            <Space/>
            <Text
                style={styles.heat}>{(heat + 34 == 34) ? 'Lo' : (heat + 34 == 40) ? 'Hi' : (unit ? heat + 34 : (((heat + 34) * 1.8) + 25).toFixed(2))}</Text>
            <RNListSlider
                value={heat}
                maximumValue={6}
                onValueChange={setHeat}
                itemStyle={{
                    borderColor: '#FFF',
                }}
                thumbStyle={{
                    borderColor: '#FFF',
                }}
            />
            <Space/>
            <Button label={translations.general.next} onPress={() => {
                // TODO: Set current test data
                setAnswers({...answers, ...{['step' + step]: (heat + 34)}});

                if ((heat + 34) > 37.5) {
                    setHasOver375(true);
                }

                if ((heat + 34) < 35.5) {
                    setHasUnder355(true);
                }

                if ((heat + 34) < 34.5) {
                    setHasUnder345(true);
                }

                if (step === 5) {
                    const newAnswers = {...answers, ...{['step' + step]: heat + 34}};
                    setAnswers(newAnswers);

                    // Calculate average
                    const leftSideAverage = (newAnswers.step3 + newAnswers.step5) / 2;
                    const rightSideAverage = (newAnswers.step2 + newAnswers.step4) / 2;

                    // Analysis result
                    let leftSideDifference = leftSideAverage <= 37 ? (37 - leftSideAverage) : (leftSideAverage - 37);
                    let rightSideDifference = rightSideAverage <= 37 ? (37 - rightSideAverage) : (rightSideAverage - 37);
                    let leftSideImmunity = ((leftSideDifference / 0.2) * 5) - 1.5;
                    let rightSideImmunity = ((rightSideDifference / 0.2) * 5) - 1.5;

                    if (hasOver375 === true) {
                        leftSideImmunity += 5;
                        rightSideImmunity += 5;
                    }

                    if (hasUnder355 === true) {
                        leftSideImmunity += 5;
                        rightSideImmunity += 5;
                    }

                    if (hasUnder345 === true) {
                        leftSideImmunity += 10;
                        rightSideImmunity += 10;
                    }

                    if (leftSideImmunity > 100) {
                        leftSideImmunity = 100;
                    }
                    if (rightSideImmunity > 100) {
                        rightSideImmunity = 100;
                    }
                    let overallImmunity = (leftSideImmunity + rightSideImmunity) / 2;

                    if (props.user.isLoggedIn === true && props.user.currentFamilyMember !== null) {
                        firebase.database().ref('pet_analysis/' + props.user.currentFamilyMember.id).push({
                            answers: newAnswers,
                            familyMember: props.user.currentFamilyMember.id,
                            createdAt: Date.now(),
                            leftSideAverage,
                            rightSideAverage,
                            leftSideImmunity,
                            rightSideImmunity,
                            overallImmunity,
                        });
                    }
                    props.navigation.navigate('PetAnalysis', {answers: newAnswers, leftSideAverage, rightSideAverage, leftSideImmunity, rightSideImmunity, overallImmunity});
                } else {
                    setStep(step + 1);
                }
                setHeat(2);
            }}/>
            <Space/>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Measure);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    text: {
        fontFamily: 'Montserrat_400Regular',
        fontSize: 18,
        color: '#FFF',
        textAlign: 'center',
    },
    heat: {
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: 30,
        color: '#FFF',
    },
    unitText: {
        fontSize: 20,
        fontFamily: 'Montserrat_600SemiBold',
        color: '#FFF',
        paddingHorizontal: 10,
    }
};
