import React from 'react';
import {Dimensions, ScrollView, Text} from "react-native";
import Button from "../components/Button";
import Header from "../components/Header";
import Space from '../components/Space';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import AutoHeightImage from 'react-native-auto-height-image';
import Colors from "../../res/constants/Colors";
import {connect} from "react-redux";
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";

function BeforeStart(props) {
    const translations = props.translate[props.translate.currentLanguage];

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton realBack={true} />)}
                headerCenter={() => (<HeaderLogo />)}
                headerRight={() => (<LanguageChangeButton />)}
            />
            <Text style={styles.text}>
                {translations.beforeStart.description}
            </Text>
            <AutoHeightImage style={{width: Dimensions.get('window').width / 3, height: (Dimensions.get('window').width * 0.6)}} source={require('../../res/images/device.png')} width={Dimensions.get('window').width / 3} />
            <Text style={styles.text}>
                {translations.beforeStart.instructions}
            </Text>
            <Button label={translations.beforeStart.goAhead} onPress={() => {props.navigation.navigate("Measure")}} />
            <Space />
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
    }
}

export default connect(mapStateToProps)(BeforeStart);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    text: {
        fontFamily: 'Montserrat_300Light',
        fontSize: 20,
        color: "#FFF",
        textAlign: 'center',
        marginTop: 40,
        marginBottom: 15,
        paddingHorizontal: 15,
    },
};
