import React from 'react';
import Button from '../Button';
import { navigate } from '../../RootNavigation';
import {connect} from 'react-redux';
import * as SecureStore from 'expo-secure-store';
import {setUser} from "../../redux/actions/UserActions";
import StyleGuide from "../../../res/constants/StyleGuide";
import {Platform} from "react-native-web";

function LoginButton(props) {
    const translations = props.translations[props.translations.currentLanguage];
    return (
        <>
        {
            props.user.isLoggedIn ?
                <Button onPress={() => {
                    if (Platform.OS === "web") {
                        localStorage.removeItem('userData');
                        localStorage.removeItem('firebase:userRole');
                        localStorage.removeItem('firebase:userName');
                        localStorage.removeItem('firebase:userUid');
			localStorage.removeItem('firebase:authUser:AIzaSyBhwDonz7ld-ORI64-IVizegMYSPGN0nr4\n:[DEFAULT]');
                        props.setUser({
                            isLoggedIn: false,
                            data: {},
                        });
                    } else {
                        SecureStore.deleteItemAsync('userData').then(() => {
                            props.setUser({
                                isLoggedIn: false,
                                data: {},
                            });
                        }).catch(err => {
                            console.log("LOGOUT ERROR", err);
                        });
                    }
                    navigate('Landing');
                }} label={translations.general.logout} wrapperStyle={styles.buttonWrapper} style={styles.buttonText} /> :
                <Button onPress={() => {
                    navigate('Login');
                }} label={translations.general.loginButton} wrapperStyle={styles.buttonWrapper} style={styles.buttonText} />
        }
        </>
    );
}

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginButton);

const styles = {
    buttonWrapper: {
        width: 30 + '%',
        height: StyleGuide.buttons.height,
    },
    buttonText: {
        fontSize: StyleGuide.buttons.fontSize,
    },
};
