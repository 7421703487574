import React from 'react';
import {Alert, Dimensions, Platform, ScrollView, Share, Text, View} from "react-native";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import { connect } from 'react-redux';
import {setUser} from "../redux/actions/UserActions";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import MenuButton from "../components/HeaderButtons/MenuButton";
import Space from "../components/Space";
import AutoHeightImage from "react-native-auto-height-image";
import Button from "../components/Button";
// import Toast from "react-native-toast-message";
import { LineChart } from "react-native-chart-kit";
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";
import * as Print from 'expo-print';

const platformSpecific = Platform.select({
    ios: {
        containerVerticalMargin: 30,
    },
    android: {
        containerVerticalMargin: 0,
    },
    web: {
        containerVerticalMargin: 30,
    },
});

function Analysis(props) {
    const answers = props.route.params.answers;
    const leftSideAverage = props.route.params.leftSideAverage;
    const leftSideImmunity = props.route.params.leftSideImmunity;
    const rightSideAverage = props.route.params.rightSideAverage;
    const rightSideImmunity = props.route.params.rightSideImmunity;
    const overallImmunity = props.route.params.overallImmunity;
    const translations = props.translate[props.translate.currentLanguage];

    // Analysis result
    const leftDataPointClick = data => {
        const pointNames = {
            'point0': translations.measurePoints.neck,
            'point1': translations.measurePoints.nape,
            'point2': translations.measurePoints.belly,
            'point3': translations.measurePoints.handIn,
            'point4': translations.measurePoints.handOut,
            'point5': translations.measurePoints.back,
            'point6': translations.measurePoints.footUp,
            'point7': translations.measurePoints.footDown,
            'point8': translations.measurePoints.ear,
        };
        // Toast.show({
        //     type: 'info',
        //     visibilityTime: 1000,
        //     position: 'bottom',
        //     text1: translations.measurePoints.left + pointNames['point' + data.index] + ': ' + data.value + ' °C',
        // })
    }

    const rightDataPointClick = data => {
        const pointNames = {
            'point0': translations.measurePoints.neck,
            'point1': translations.measurePoints.nape,
            'point2': translations.measurePoints.belly,
            'point3': translations.measurePoints.handIn,
            'point4': translations.measurePoints.handOut,
            'point5': translations.measurePoints.back,
            'point6': translations.measurePoints.footUp,
            'point7': translations.measurePoints.footDown,
            'point8': translations.measurePoints.ear,
        };
        // Toast.show({
        //     type: 'info',
        //     visibilityTime: 1000,
        //     position: 'bottom',
        //     text1: translations.measurePoints.right + pointNames['point' + data.index] + ': ' + data.value + ' °C',
        // })
    }

    const chartConfig = {
        decimalPlaces: 2, // optional, defaults to 2dp
        backgroundGradientFromOpacity: 0,
        backgroundGradientToOpacity: 0,
        strokeWidth: 3,
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        style: {
            borderRadius: 16
        },
        propsForDots: {
            r: 5,
            // strokeWidth: "2",
            // stroke: "#f3f3f3"
        }
    };

    const leftSideData = [
        answers.step1,
        answers.step3,
        answers.step5,
        answers.step7,
        answers.step9,
        answers.step11,
        answers.step13,
        answers.step15,
    ];

    if (answers.step17) {
        leftSideData.push(answers.step17);
    }

    const rightSideData = [
        answers.step2,
        answers.step4,
        answers.step6,
        answers.step8,
        answers.step10,
        answers.step12,
        answers.step14,
        answers.step16,
    ];

    if (answers.step18) {
        rightSideData.push(answers.step18);
    }

    return (
        <View style={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton />)}
                headerCenter={() => (props.user.isLoggedIn ? <HeaderLogo /> : <></>)}
                headerRight={() => (<MenuButton />)}
            />
            <ScrollView contentContainerStyle={styles.middleContainer}>
                <Text style={styles.heading}>{translations.analysis.heading}</Text>
                <Space />
                <View>
                    <AutoHeightImage style={{width: Dimensions.get('window').width, height: Dimensions.get('window').width}} source={require('../../res/images/chart-bg.png')} width={Dimensions.get('window').width} />
                    <View style={{
                        position: 'absolute',
                        left: 0,
                        top: Dimensions.get("window").width * 0.080,
                        width: Dimensions.get('window').width * 0.390,
                        height: Dimensions.get('window').width * 0.600,
                        backgroundColor: 'rgba(0, 0, 0, .1)',
                        overflow: 'hidden',
                    }}>
                        <LineChart
                            data={{
                                datasets: [
                                    {
                                        data: [34],
                                        color: () => 'rgba(0, 0, 0, 0)',
                                    },
                                    {
                                        data: leftSideData,
                                        color: (opacity = 1) => `rgba(255, 255, 255, 1)`, // optional
                                    },
                                    {
                                        data: [40],
                                        color: () => 'rgba(0, 0, 0, 0)',
                                    },
                                ]
                            }}
                            onDataPointClick={rightDataPointClick}
                            width={Dimensions.get('window').width * 0.550}
                            height={Dimensions.get('window').width * 0.780}
                            withHorizontalLabels={false}
                            // yAxisInterval={1} // optional, defaults to 1
                            chartConfig={chartConfig}
                            style={{
                                marginVertical: -12,
                                borderRadius: 16,
                                left: -52,
                            }}
                        />
                    </View>
                    <View style={{
                        position: 'absolute',
                        right: 0,
                        top: Dimensions.get("window").width * 0.080,
                        width: Dimensions.get('window').width * 0.390,
                        height: Dimensions.get('window').width * 0.600,
                        backgroundColor: 'rgba(0, 0, 0, .1)',
                        overflow: 'hidden',
                    }}>
                        <LineChart
                            data={{
                                datasets: [
                                    {
                                        data: [34],
                                        color: () => 'rgba(0, 0, 0, 0)',
                                    },
                                    {
                                        data: rightSideData,
                                        color: (opacity = 1) => `rgba(255, 255, 255, 1)`, // optional
                                    },
                                    {
                                        data: [40],
                                        color: () => 'rgba(0, 0, 0, 0)',
                                    },
                                ]
                            }}
                            onDataPointClick={leftDataPointClick}
                            width={Dimensions.get('window').width * 0.540}
                            height={Dimensions.get('window').width * 0.780}
                            withHorizontalLabels={false}
                            // yAxisInterval={1} // optional, defaults to 1
                            chartConfig={chartConfig}
                            style={{
                                marginVertical: -12,
                                borderRadius: 16,
                                left: -50,
                            }}
                        />
                    </View>
                    {/* Left Text */}
                    <Text style={{...styles.analysisResultText, fontSize: Dimensions.get('window').width > 800 ? 26 : props.translate.currentLanguage == 'de' ? 16 : 18,}}>{translations.analysis.leftSide.replace('%s', parseInt(leftSideImmunity) * -1)}</Text>
                    {/* Right Text */}
                    <Text style={{...styles.analysisResultText, right: 0, fontSize: Dimensions.get('window').width > 800 ? 26 : props.translate.currentLanguage == 'de' ? 16 : 18,}}>{translations.analysis.rightSide.replace('%s', parseInt(rightSideImmunity) * -1)}</Text>
                </View>
                <View style={styles.hr} />
                <Text style={styles.resultHeading}>{translations.analysis.overAll.replace('%s', parseInt(overallImmunity) * -1)}</Text>
            </ScrollView>
            <View style={styles.buttonsWrapper}>
                <Button wrapperStyle={styles.button} style={{fontSize: props.translate.currentLanguage == 'de' ? 15 : 18,}} label={translations.analysis.saveResults} onPress={() => {
                    const mpt = translations.measurePoints;
                    let message = mpt.description + '\n\n';
                    message += mpt.rightSide + '\n';
                    message += mpt.neck + ': ' + answers.step1 + '°C' + '\n';
                    message += mpt.nape + ': ' + answers.step3 + '°C' + '\n';
                    message += mpt.handIn + ': ' + answers.step5 + '°C' + '\n';
                    message += mpt.handOut + ': ' + answers.step7 + '°C' + '\n';
                    message += mpt.belly + ': ' + answers.step9 + '°C' + '\n';
                    message += mpt.back + ': ' + answers.step11 + '°C' + '\n';
                    message += mpt.footUp + ': ' + answers.step13 + '°C' + '\n';
                    message += mpt.footDown + ': ' + answers.step15 + '°C' + '\n\n';
                    message += mpt.leftSide + '\n';
                    message += mpt.neck + ': ' + answers.step2 + '°C' + '\n';
                    message += mpt.nape + ': ' + answers.step4 + '°C' + '\n';
                    message += mpt.handIn + ': ' + answers.step6 + '°C' + '\n';
                    message += mpt.handOut + ': ' + answers.step8 + '°C' + '\n';
                    message += mpt.belly + ': ' + answers.step10 + '°C' + '\n';
                    message += mpt.back + ': ' + answers.step12 + '°C' + '\n';
                    message += mpt.footUp + ': ' + answers.step14 + '°C' + '\n';
                    message += mpt.footDown + ': ' + answers.step16 + '°C';

                    Share.share({
                        message: message,
                    }).then(() => {
                        console.log('SHARED');
                    }).catch(err => {
                        console.log('SHARE ERROR', err);
                    });
                }} />
                <Button wrapperStyle={styles.button} style={{fontSize: props.translate.currentLanguage == 'de' ? 15 : 18,}} label={translations.analysis.moreInfo} onPress={() => {
                    props.navigation.navigate('ProductSuggestion', {
                        overallImmunity: parseInt(overallImmunity),
                    });
                }} />
            </View>
        </View>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
        user: state.UserReducer,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Analysis);

const styles = {
    wrapper: {
        backgroundColor: Colors.mainBackground,
        flex: 1,
    },
    middleContainer: {
        alignItems: 'center',
        justifyContent: 'center',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        marginTop: platformSpecific.containerVerticalMargin,
        width: 100 + '%',
    },
    heading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: 28,
        textAlign: 'center',
    },
    analysisResultText: {
        width: Dimensions.get('window').width * 0.375,
        marginTop: 5,
        position: 'absolute',
        top: Dimensions.get('window').width * 0.680,
        fontFamily: 'Montserrat_600SemiBold',
        color: '#FFF',
        textAlign: 'center',
    },
    hr: {
        width: 70 + '%',
        borderWidth: 1,
        borderColor: '#FFF',
        marginVertical: 20,
    },
    resultHeading: {
        fontSize: 24,
        fontFamily: 'Montserrat_600SemiBold',
        color: '#FFF',
        width: 80 + '%',
        textAlign: 'center',
    },
    button: {
        width: 48 + '%',
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 100 + '%',
        marginVertical: 20,
    },
};
