import React from 'react';
import {Dimensions, Linking, Platform, ScrollView, StyleSheet, Text, TouchableOpacity, View} from "react-native";
import Colors from "../../res/constants/Colors";
import Header from "./Header";
import ShareButton from "./HeaderButtons/ShareButton";
import HeaderLogo from "./HeaderButtons/HeaderLogo";
import CloseMenuButton from "./HeaderButtons/CloseMenuButton";
import Space from "./Space";
import {setMenu} from "../redux/actions/MenuActions";
import {connect} from "react-redux";
import { navigate } from '../RootNavigation';
import AutoHeightImage from "react-native-auto-height-image";

function Menu(props) {
    const translations = props.translations[props.translations.currentLanguage];

    return (
        <>
            {props.menuState.showMenu === true ?
                <View style={{
                    backgroundColor: Colors.mainBackground,
                }}>
                    <ScrollView contentContainerStyle={{
                        alignItems: 'center',
                        minHeight: Dimensions.get('window').height - 50,
                    }}>
                        {/*<Space />*/}
                        <Header
                            headerLeft={() => {
                                return (<ShareButton/>);
                            }}
                            headerCenter={() => {
                                return (<HeaderLogo/>);
                            }}
                            headerRight={() => {
                                return <CloseMenuButton/>
                            }}
                        />
                        <Text style={styles.menuHeading}>MORE</Text>
                        <View style={{
                            paddingHorizontal: 15,
                        }}>
                            {/*<TouchableOpacity style={styles.menuButton}>*/}
                            {/*    <Text style={styles.menuButtonText}>Family Account</Text>*/}
                            {/*</TouchableOpacity>*/}
                            {props.user.isLoggedIn ? <></> :
                                <TouchableOpacity style={styles.menuButton} onPress={() => {
                                    navigate('Login');
                                    props.setMenu({showMenu: false});
                                }}>
                                    <Text style={styles.menuButtonText}>{translations.menu.membersLogin}</Text>
                                </TouchableOpacity>
                            }
                            <TouchableOpacity style={styles.menuButton} onPress={() => {
                                navigate('FreeAdvising');
                                props.setMenu({showMenu: false});
                            }}>
                                <Text style={styles.menuButtonText}>{translations.menu.freeSupport}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.menuButton} onPress={() => {
                                navigate('SeeMore');
                                props.setMenu({
                                    showMenu: false,
                                });
                            }}>
                                <Text style={styles.menuButtonText}>{translations.menu.noHeatNoLive}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.menuButton} onPress={() => {
                                navigate('LegalPoints');
                                props.setMenu({showMenu: false});
                            }}>
                                <Text style={styles.menuButtonText}>{translations.menu.legalPoints}</Text>
                            </TouchableOpacity>
                            <TouchableOpacity style={styles.menuButton} onPress={() => {
                                if (Platform.OS === 'web') {
                                    window.open('https://nitt.app/panel', '_blank');
                                } else {
                                    Linking.openURL('https://nitt.app/panel');
                                }
                            }}>
                                <Text style={styles.menuButtonText}>{translations.menu.consultantLogin}</Text>
                            </TouchableOpacity>
                            <Space/>
                        </View>
                    </ScrollView>
                    <TouchableOpacity style={{justifyContent: 'center', alignItems: 'center'}} onPress={() => {
                        Linking.openURL('http://data212.com');
                    }}>
                        <AutoHeightImage style={{width: 100, height: 100 * 0.40}} width={100} source={require('../../res/images/data-212-logo.png')} />
                    </TouchableOpacity>
                </View>
                : <></>}
        </>
    );
}

const styles = {
    menuHeading: {
        fontFamily: 'Montserrat_800ExtraBold',
        color: '#FFF',
        fontSize: 24,
        textAlign: 'center',
        marginVertical: 30,
    },
    menuButton: {
        width: 100 + '%',
        paddingVertical: 10,
    },
    menuButtonText: {
        fontFamily: 'Montserrat_600SemiBold',
        color: '#FFF',
        fontSize: 20,
    },
};

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
        menuState: state.MenuReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setMenu: payload => dispatch(setMenu(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
