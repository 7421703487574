const INITIAL_STATE = {
    isLoggedIn: false,
    data: {},
    hasInitialized: false,
    familyMembers: null,
    pets: null,
    currentFamilyMember: null, // Current family member
    isActive: false,
    unit: 'c',
    earTesting: false,
};

const UserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_USER":
        case "LOGIN_USER":
        case "INIT_USER":
            return {...state, ...action.payload}
        default:
            return state;
    }
}

export default UserReducer;
