import React from 'react';
import {
    Dimensions,
    Linking,
    Platform,
    ScrollView,
    Text,
    View
} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import { connect } from 'react-redux';
import Space from "../components/Space";
import 'firebase/auth';
import {setUser} from "../redux/actions/UserActions";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import AutoHeightImage from "react-native-auto-height-image";
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function productSuggestion(props) {
    const translations = props.translate[props.translate.currentLanguage];
    const overallImmunity = props.route.params.overallImmunity;
    const imageWidth = Dimensions.get('window').width - 70;
    const imageHeight = imageWidth * 0.667;

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton realBack={true} />)}
                headerCenter={() => (<HeaderLogo />)}
                headerRight={() => (<LanguageChangeButton />)}
            />
            <Space />
            <Text style={styles.screenHeading}>{translations.suggestion.heading}</Text>
            <Space />
            {
                overallImmunity < 20 ?
                <Text style={styles.content}>
                    {translations.suggestion.content1}
                </Text> : overallImmunity < 40 ?
                <>
                    <AutoHeightImage style={{width: imageWidth, height: imageHeight}} source={require('../../res/images/kits/mft-basic.png')} width={imageWidth} />
                    <Text style={styles.content}>
                        {translations.suggestion.content2.replace('%s', overallImmunity)}
                    </Text>
                    <Space />
                    <Text style={styles.content}>
                        {translations.suggestion.content3}
                    </Text>
                    <AutoHeightImage style={{width: imageWidth, height: imageHeight}} source={require('../../res/images/kits/mft-beauty.png')} width={imageWidth} />
                </> : overallImmunity < 60 ?
                <>
                    <AutoHeightImage style={{width: imageWidth, height: imageHeight}} source={require('../../res/images/kits/mft-advanced.png')} width={imageWidth} />
                    <Text style={styles.content}>
                        {translations.suggestion.content4.replace('%s', overallImmunity)}
                    </Text>
                </> :
                <>
                    <AutoHeightImage style={{width: imageWidth, height: imageHeight}} source={require('../../res/images/kits/mft-chronic.png')} width={imageWidth} />
                    <Text style={styles.content}>
                        {translations.suggestion.content5.replace('%s', overallImmunity)}
                    </Text>
                </>
            }
            <View style={styles.buttonsWrapper}>
                <Button label={translations.suggestion.freeAdvising} wrapperStyle={styles.button} style={{fontSize: 13,}} onPress={() => {
                    props.navigation.push('FreeAdvising');
                }} />
                <Button label={translations.suggestion.preventionKits} wrapperStyle={styles.button} style={{fontSize: 13,}} onPress={() => {
                    Linking.openURL('https://www.chaava.com/programs/');
                }} />
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(productSuggestion);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    screenHeading: {
        fontSize: 24,
        fontFamily: 'Montserrat_800ExtraBold',
        color: '#FFF',
        textAlign: 'center',
    },
    content: {
        fontSize: 18,
        fontFamily: 'Montserrat_400Regular',
        color: '#FFFFFF',
        paddingHorizontal: 20,
        width: 100 + '%',
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 90 + '%',
        marginBottom: 20,
        marginTop: 10,
    },
    button: {
        width: 49 + '%',
    },
};
