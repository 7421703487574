import React, {useState} from 'react';
import {Dimensions, Image, ScrollView, Switch, Text, View} from 'react-native';
import Header from "../components/Header";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import MenuButton from "../components/HeaderButtons/MenuButton";
import RNListSlider from 'react-native-list-slider';
import AutoHeightImage from 'react-native-auto-height-image';
import Space from "../components/Space";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import * as firebase from "firebase";
import {setUser} from "../redux/actions/UserActions";
import {connect} from 'react-redux';
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";

function Measure(props) {
    const translations = props.translate[props.translate.currentLanguage];
    const [heat, setHeat] = useState(2);
    const [step, setStep] = useState(1);
    const [unit, setUnit] = useState(true);
    const [hasOver375, setHasOver375] = useState(false);
    const [hasUnder355, setHasUnder355] = useState(false);
    const [hasUnder345, setHasUnder345] = useState(false);
    const [answers, setAnswers] = useState({
        step1: 0,
        step2: 0,
        step3: 0,
        step4: 0,
        step5: 0,
        step6: 0,
        step7: 0,
        step8: 0,
        step9: 0,
        step10: 0,
        step11: 0,
        step12: 0,
        step13: 0,
        step14: 0,
        step15: 0,
        step16: 0,
    });
    const pointNames = {
        step1: translations.points.rightNeck,
        step2: translations.points.leftNeck,
        step3: translations.points.rightNape,
        step4: translations.points.leftNape,
        step5: translations.points.rightHandIn,
        step6: translations.points.leftHandIn,
        step7: translations.points.rightHandOut,
        step8: translations.points.leftHandOut,
        step9: translations.points.rightBelly,
        step10: translations.points.leftBelly,
        step11: translations.points.rightBack,
        step12: translations.points.leftBack,
        step13: translations.points.rightFootUp,
        step14: translations.points.leftFootUp,
        step15: translations.points.rightFootDown,
        step16: translations.points.leftFootDown,
        step17: translations.points.leftEar,
        step18: translations.points.rightEar,
    };

    const pointerWidth = (Dimensions.get('window').width - 50) * 0.13;
    const imageWidth = Dimensions.get('window').width - 50;
    const pointerPositions = {
        step1: {
            left: -(imageWidth / 2) + ((imageWidth * .420)),
            top: (imageWidth * .640) + pointerWidth,
        },
        step2: {
            left: -(imageWidth / 2) + ((imageWidth * .580)),
            top: (imageWidth * .640) + pointerWidth,
        },
        step3: {
            left: -(imageWidth / 2) + ((imageWidth * .558)),
            top: (imageWidth * .60) + pointerWidth,
        },
        step4: {
            left: -(imageWidth / 2) + ((imageWidth * .442)),
            top: (imageWidth * .60) + pointerWidth,
        },
        step5: {
            left: -(imageWidth / 2) + ((imageWidth * .105)),
            top: (imageWidth * .574) + pointerWidth,
        },
        step6: {
            left: -(imageWidth / 2) + ((imageWidth * .895)),
            top: (imageWidth * .574) + pointerWidth,
        },
        step7: {
            left: -(imageWidth / 2) + ((imageWidth * .880)),
            top: (imageWidth * .6) + pointerWidth,
        },
        step8: {
            left: -(imageWidth / 2) + ((imageWidth * .120)),
            top: (imageWidth * .6) + pointerWidth,
        },
        step9: {
            left: -(imageWidth / 2) + ((imageWidth * .400)),
            top: (imageWidth * .385) + pointerWidth,
        },
        step10: {
            left: -(imageWidth / 2) + ((imageWidth * .600)),
            top: (imageWidth * .385) + pointerWidth,
        },
        step11: {
            left: -(imageWidth / 2) + ((imageWidth * .600)),
            top: (imageWidth * .365) + pointerWidth,
        },
        step12: {
            left: -(imageWidth / 2) + ((imageWidth * .400)),
            top: (imageWidth * .365) + pointerWidth,
        },
        step13: {
            left: -(imageWidth / 2) + ((imageWidth * .455)),
            top: (imageWidth * .786) + pointerWidth,
        },
        step14: {
            left: -(imageWidth / 2) + ((imageWidth * .545)),
            top: (imageWidth * .786) + pointerWidth,
        },
        step15: {
            left: -(imageWidth / 2) + ((imageWidth * .69)),
            top: (imageWidth * .74) + pointerWidth,
        },
        step16: {
            left: -(imageWidth / 2) + ((imageWidth * .84)),
            top: (imageWidth * .74) + pointerWidth,
        },
        step17: {
            left: -(imageWidth / 2) + ((imageWidth * .31)),
            top: (imageWidth * .400) + pointerWidth,
        },
        step18: {
            left: -(imageWidth / 2) + ((imageWidth * .69)),
            top: (imageWidth * .4) + pointerWidth,
        },
    };

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton realBack={true} />)}
                headerCenter={() => (<HeaderLogo/>)}
                headerRight={() => (<MenuButton/>)}
            />
            <Image source={require('../../res/images/blue_pointer.gif')} style={{
                zIndex: 1,
                left: pointerPositions["step" + step].left,
                top: pointerPositions["step" + step].top,
                width: pointerWidth,
                height: pointerWidth,
            }}/>
            {
                step <= 2 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/head-front.png')} width={imageWidth}/>)
                    : step <= 4 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/head-back.png')} width={imageWidth}/>)
                    : step <= 6 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/chest-front.png')} width={imageWidth}/>)
                    : step <= 8 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/chest-back.png')} width={imageWidth}/>)
                    : step <= 10 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/chest-front.png')} width={imageWidth}/>)
                    : step <= 12 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/chest-back.png')} width={imageWidth}/>)
                    : step <= 14 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/foot-front.png')} width={imageWidth}/>)
                    : step <= 16 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/foot-back.png')} width={imageWidth}/>)
                    : step <= 18 ?
                    (<AutoHeightImage style={{width: imageWidth, height: imageWidth}} source={require('../../res/images/steps/head-front.png')} width={imageWidth}/>)
                    : (<></>)
            }
            <Text style={{...styles.text, marginBottom: 15,}}>{pointNames["step" + step]}</Text>
            <Text style={styles.text}>{translations.measure.instructions}</Text>
            <Space/>
            <View style={{
                flexDirection: 'row',
                alignItems: 'center',
                justifyContent: 'center',
            }}>
                <Text style={styles.unitText}>°F</Text>
                <Switch
                    trackColor={{false: '#d3d3d3', true: '#d3d3d3'}}
                    thumbColor={Colors.primary}
                    ios_backgroundColor="#3e3e3e"
                    onValueChange={setUnit}
                    value={unit}
                />
                <Text style={styles.unitText}>°C</Text>
            </View>
            <Space/>
            <Text
                style={styles.heat}>{(heat + 34 == 34) ? 'Lo' : (heat + 34 == 40) ? 'Hi' : (unit ? heat + 34 : (((heat + 34) * 1.8) + 25).toFixed(2))}</Text>
            <RNListSlider
                value={heat}
                maximumValue={6}
                onValueChange={setHeat}
                itemStyle={{
                    borderColor: '#FFF',
                }}
                thumbStyle={{
                    borderColor: '#FFF',
                }}
            />
            <Space/>
            <Button label={translations.general.next} onPress={() => {
                // TODO: Set current test data
                setAnswers({...answers, ...{['step' + step]: (heat + 34)}});

                if ((heat + 34) > 37.5) {
                    setHasOver375(true);
                }

                if ((heat + 34) < 35.5) {
                    setHasUnder355(true);
                }

                if ((heat + 34) < 34.5) {
                    setHasUnder345(true);
                }

                if ((step === 16 && props.user.earTesting === false) || (step === 18)) {
                    const newAnswers = {...answers, ...{['step' + step]: heat + 34}};
                    setAnswers(newAnswers);

                    // Calculate average
                    const leftSideAverage = (newAnswers.step1 + newAnswers.step3 + newAnswers.step5 + newAnswers.step7 + newAnswers.step9 + newAnswers.step11 + newAnswers.step13 + newAnswers.step15) / 8;
                    const rightSideAverage = (newAnswers.step2 + newAnswers.step4 + newAnswers.step6 + newAnswers.step8 + newAnswers.step10 + newAnswers.step12 + newAnswers.step14 + newAnswers.step16) / 8;

                    // Analysis result
                    let leftSideDifference = leftSideAverage <= 37 ? (37 - leftSideAverage) : (leftSideAverage - 37);
                    let rightSideDifference = rightSideAverage <= 37 ? (37 - rightSideAverage) : (rightSideAverage - 37);
                    let leftSideImmunity = ((leftSideDifference / 0.2) * 5) - 1.5;
                    let rightSideImmunity = ((rightSideDifference / 0.2) * 5) - 1.5;

                    if (hasOver375 === true) {
                        console.log('Over 37.5');
                        leftSideImmunity += 5;
                        rightSideImmunity += 5;
                    }

                    if (hasUnder355 === true) {
                        console.log('Under 35.5');
                        leftSideImmunity += 5;
                        rightSideImmunity += 5;
                    }

                    if (hasUnder345 === true) {
                        console.log('Under 34.5');
                        leftSideImmunity += 10;
                        rightSideImmunity += 10;
                    }

                    if (leftSideImmunity > 100) {
                        leftSideImmunity = 100;
                    }
                    if (rightSideImmunity > 100) {
                        rightSideImmunity = 100;
                    }
                    let overallImmunity = (leftSideImmunity + rightSideImmunity) / 2;

                    if (props.user.isLoggedIn === true) {
                        firebase.database().ref('analysis/' + props.user.data.user.uid).push({
                            answers: newAnswers,
                            familyMember: props.user.currentFamilyMember !== null ? props.user.currentFamilyMember.id : null,
                            createdAt: Date.now(),
                            leftSideAverage,
                            rightSideAverage,
                            leftSideImmunity,
                            rightSideImmunity,
                            overallImmunity,
                        });
                    }
                    props.navigation.navigate('Analysis', {answers: newAnswers, leftSideAverage, rightSideAverage, leftSideImmunity, rightSideImmunity, overallImmunity});
                } else {
                    setStep(step + 1);
                }
                setHeat(2);
            }}/>
            <Space/>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Measure);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    text: {
        fontFamily: 'Montserrat_400Regular',
        fontSize: 18,
        color: '#FFF',
        textAlign: 'center',
    },
    heat: {
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: 30,
        color: '#FFF',
    },
    unitText: {
        fontSize: 20,
        fontFamily: 'Montserrat_600SemiBold',
        color: '#FFF',
        paddingHorizontal: 10,
    }
};
