import React, {useEffect, useState} from 'react';
import {
    ActivityIndicator,
    Dimensions,
    Platform,
    ScrollView,
    Text,
    TouchableOpacity,
    View
} from "react-native";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import {connect} from 'react-redux';
import {setUser} from "../redux/actions/UserActions";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import MenuButton from "../components/HeaderButtons/MenuButton";
import Space from "../components/Space";
import AutoHeightImage from "react-native-auto-height-image";
// import Toast from "react-native-toast-message";
import {LineChart} from "react-native-chart-kit";
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";
import * as firebase from 'firebase';

const platformSpecific = Platform.select({
    ios: {
        containerVerticalMargin: 30,
    },
    android: {
        containerVerticalMargin: 0,
    },
    web: {
        containerVerticalMargin: 30,
    },
});

function PastAnalysis(props) {
    // const answers = props.route.params.answers;
    const translations = props.translate[props.translate.currentLanguage];
    const [activeMonth, setActiveMonth] = useState(1);
    const [isFetching, setIsFetching] = useState(true);
    const [leftSideAverage, setLeftSideAverage] = useState(36);
    const [rightSideAverage, setRightSideAverage] = useState(36);
    const [leftSideImmunity, setLeftSideImmunity] = useState(36);
    const [rightSideImmunity, setRightSideImmunity] = useState(36);
    const [noData, setNoData] = useState(false);
    const [answers, setAnswers] = useState({
        step1: 36,
        step2: 36,
        step3: 36,
        step4: 36,
        step5: 36,
    });

    useEffect(() => {
        fetchAnalysis(1);
    }, []);

    // Analysis result
    const leftDataPointClick = data => {
        const pointNames = {
            'point0': translations.measurePoints.neck,
            'point1': translations.measurePoints.nape,
            'point2': translations.measurePoints.belly,
            'point3': translations.measurePoints.handIn,
            'point4': translations.measurePoints.handOut,
            'point5': translations.measurePoints.back,
            'point6': translations.measurePoints.footUp,
            'point7': translations.measurePoints.footDown,
        };
        // Toast.show({
        //     type: 'info',
        //     visibilityTime: 1000,
        //     position: 'bottom',
        //     text1: translations.measurePoints.left + pointNames['point' + data.index] + ': ' + data.value + ' °C',
        // })
    }

    const rightDataPointClick = data => {
        const pointNames = {
            'point0': translations.measurePoints.neck,
            'point1': translations.measurePoints.nape,
            'point2': translations.measurePoints.belly,
            'point3': translations.measurePoints.handIn,
            'point4': translations.measurePoints.handOut,
            'point5': translations.measurePoints.back,
            'point6': translations.measurePoints.footUp,
            'point7': translations.measurePoints.footDown,
        };
        // Toast.show({
        //     type: 'info',
        //     visibilityTime: 1000,
        //     position: 'bottom',
        //     text1: translations.measurePoints.right + pointNames['point' + data.index] + ': ' + data.value + ' °C',
        // })
    }

    const chartConfig = {
        decimalPlaces: 2, // optional, defaults to 2dp
        backgroundGradientFromOpacity: 0,
        backgroundGradientToOpacity: 0,
        strokeWidth: 3,
        color: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        labelColor: (opacity = 1) => `rgba(255, 255, 255, ${opacity})`,
        style: {
            borderRadius: 16
        },
        propsForDots: {
            r: 5,
            // strokeWidth: "2",
            // stroke: "#f3f3f3"
        }
    };

    const fetchAnalysis = months => {
        setActiveMonth(months);
        setIsFetching(true);
        const currentTimestamp = parseInt(new Date().getTime());
        const monthInSeconds = 2628000000;
        const startTime = currentTimestamp - monthInSeconds * months;
        const answersSum = {
            step1: 0,
            step2: 0,
            step3: 0,
            step4: 0,
            step5: 0,
        };
        const resultsSum = {
            leftSideImmunity: 0,
            leftSideAverage: 0,
            rightSideImmunity: 0,
            rightSideAverage: 0,
            overallImmunity: 0,
        };

        firebase.database().ref('pet_analysis/' + props.user.currentFamilyMember.id).once('value', snapshot => {
            if (null !== snapshot.val()) {
                const data = snapshot.val();
                const keys = Object.keys(data);
                let totalCount = 0;

                keys.map(key => {
                    if (data[key].createdAt > startTime) {
                        // Check for family member
                        if (props.user.currentFamilyMember === null) {
                            if (!data[key].familyMember) {
                                const answers = data[key].answers;
                                for (let i = 1; i <= 5; i++) {
                                    answersSum['step' + i] += parseFloat(answers['step' + i]);
                                }
                                resultsSum.leftSideImmunity += data[key].leftSideImmunity;
                                resultsSum.leftSideAverage += data[key].leftSideAverage;
                                resultsSum.rightSideImmunity += data[key].rightSideImmunity;
                                resultsSum.rightSideAverage += data[key].rightSideAverage;
                                resultsSum.overallImmunity += data[key].overallImmunity;
                                totalCount++;
                            }
                        } else {
                            if (data[key].familyMember !== undefined && data[key].familyMember === props.user.currentFamilyMember.id) {
                                const answers = data[key].answers;
                                for (let i = 1; i <= 5; i++) {
                                    answersSum['step' + i] += parseFloat(answers['step' + i]);
                                }
                                resultsSum.leftSideImmunity += data[key].leftSideImmunity;
                                resultsSum.leftSideAverage += data[key].leftSideAverage;
                                resultsSum.rightSideImmunity += data[key].rightSideImmunity;
                                resultsSum.rightSideAverage += data[key].rightSideAverage;
                                resultsSum.overallImmunity += data[key].overallImmunity;
                                totalCount++;
                            }
                        }
                    }
                })

                if (totalCount === 0) {
                    setNoData(true);
                    return;
                }

                resultsSum.leftSideImmunity = resultsSum.leftSideImmunity / totalCount;
                resultsSum.rightSideImmunity = resultsSum.rightSideImmunity / totalCount;
                resultsSum.leftSideAverage = resultsSum.leftSideAverage / totalCount;
                resultsSum.rightSideAverage = resultsSum.rightSideAverage / totalCount;
                resultsSum.overallImmunity = resultsSum.overallImmunity / totalCount;

                setAnswers({
                    step1: answersSum.step1 / totalCount,
                    step2: answersSum.step2 / totalCount,
                    step3: answersSum.step3 / totalCount,
                    step4: answersSum.step4 / totalCount,
                    step5: answersSum.step5 / totalCount,
                });

                setLeftSideAverage(resultsSum.leftSideAverage);
                setRightSideAverage(resultsSum.rightSideAverage);
                setLeftSideImmunity(parseInt(resultsSum.leftSideImmunity));
                setRightSideImmunity(parseInt(resultsSum.rightSideImmunity));
                setIsFetching(false);
            } else {
                setNoData(true);
            }
        });
    }

    return (
        <ScrollView contentContainerStyle={{...styles.middleContainer, ...((isFetching || noData) ? {flex: 1} : {})}}>
            <View>
                <Header
                    headerLeft={() => (<MenuBackButton/>)}
                    headerCenter={() => (props.user.isLoggedIn ? <HeaderLogo/> : <></>)}
                    headerRight={() => (<MenuButton/>)}
                />
                <Space height={15}/>
                <Text
                    style={styles.heading}>{translations.analysis.analysisFor.replace('%s', props.user.currentFamilyMember !== null ? props.user.currentFamilyMember.name : translations.landing.me)}</Text>
            </View>
            {
                (isFetching || noData) ? noData ? <Text style={styles.noAnalysis}>{translations.errors.noData}</Text> :
                    <ActivityIndicator size={'large'} color={Colors.primary}/> :
                    <>
                        <View>
                            <AutoHeightImage
                                style={{width: Dimensions.get('window').width, height: Dimensions.get('window').width}}
                                source={require('../../res/images/chart-pet-bg.png')}
                                width={Dimensions.get('window').width}
                            />
                            <View style={{
                                position: 'absolute',
                                left: 0,
                                top: Dimensions.get("window").width * 0.080,
                                width: Dimensions.get('window').width * 0.390,
                                height: Dimensions.get('window').width * 0.600,
                                backgroundColor: 'rgba(0, 0, 0, .1)',
                                overflow: 'hidden',
                            }}>
                                <LineChart
                                    data={{
                                        datasets: [
                                            {
                                                data: [34],
                                                color: () => 'rgba(0, 0, 0, 0)',
                                            },
                                            {
                                                data: [
                                                    answers.step1,
                                                    answers.step3,
                                                    answers.step5,
                                                ],
                                                color: (opacity = 1) => `rgba(255, 255, 255, 1)`, // optional
                                            },
                                            {
                                                data: [40],
                                                color: () => 'rgba(0, 0, 0, 0)',
                                            },
                                        ]
                                    }}
                                    onDataPointClick={rightDataPointClick}
                                    width={Dimensions.get('window').width * 0.550}
                                    height={Dimensions.get('window').width * 0.780}
                                    withHorizontalLabels={false}
                                    // yAxisInterval={1} // optional, defaults to 1
                                    chartConfig={chartConfig}
                                    style={{
                                        marginVertical: -12,
                                        borderRadius: 16,
                                        left: -52,
                                    }}
                                />
                            </View>
                            <View style={{
                                position: 'absolute',
                                right: 0,
                                top: Dimensions.get("window").width * 0.080,
                                width: Dimensions.get('window').width * 0.390,
                                height: Dimensions.get('window').width * 0.600,
                                backgroundColor: 'rgba(0, 0, 0, .1)',
                                overflow: 'hidden',
                            }}>
                                <LineChart
                                    data={{
                                        datasets: [
                                            {
                                                data: [34],
                                                color: () => 'rgba(0, 0, 0, 0)',
                                            },
                                            {
                                                data: [
                                                    answers.step1,
                                                    answers.step2,
                                                    answers.step4,
                                                ],
                                                color: (opacity = 1) => `rgba(255, 255, 255, 1)`, // optional
                                            },
                                            {
                                                data: [40],
                                                color: () => 'rgba(0, 0, 0, 0)',
                                            },
                                        ]
                                    }}
                                    onDataPointClick={leftDataPointClick}
                                    width={Dimensions.get('window').width * 0.540}
                                    height={Dimensions.get('window').width * 0.780}
                                    withHorizontalLabels={false}
                                    // yAxisInterval={1} // optional, defaults to 1
                                    chartConfig={chartConfig}
                                    style={{
                                        marginVertical: -12,
                                        borderRadius: 16,
                                        left: -50,
                                    }}
                                />
                            </View>
                            {/* Left Text */}
                            <Text
                                style={styles.analysisResultText}>{translations.analysis.leftSide.replace('%s', leftSideImmunity * -1)}</Text>
                            {/* Right Text */}
                            <Text style={{
                                ...styles.analysisResultText,
                                right: 0
                            }}>{translations.analysis.rightSide.replace('%s', rightSideImmunity * -1)}</Text>
                        </View>
                        <View style={styles.hr}/>
                        <Text
                            style={styles.resultHeading}>{translations.analysis.overAll.replace('%s', ((leftSideImmunity + rightSideImmunity) / 2) * -1)}</Text>
                    </>
            }
            <View style={styles.barWrapper}>
                <View style={styles.bar}>
                    <View style={styles.barButtonsWrapper}>
                        <View style={{flex: 1, height: 45, backgroundColor: activeMonth == 1 ? 'red' : 'white'}}/>
                        <View style={{flex: 1, height: 45, backgroundColor: activeMonth == 2 ? 'red' : 'white'}}/>
                        <View style={{flex: 1, height: 45, backgroundColor: activeMonth == 3 ? 'red' : 'white'}}/>
                        <View style={{flex: 1, height: 45, backgroundColor: activeMonth == 6 ? 'red' : 'white'}}/>
                        <View style={{flex: 1, height: 45, backgroundColor: activeMonth == 12 ? 'red' : 'white'}}/>
                    </View>
                </View>
                <View style={styles.barButtonsWrapper}>
                    <TouchableOpacity onPress={() => {
                        fetchAnalysis(1);
                    }} style={styles.barButton}>
                        <Text
                            style={{...styles.barButtonText, ...(activeMonth == 1 ? {color: '#FFF'} : {})}}>1{'\n'}{translations.general.month}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        fetchAnalysis(2);
                    }} style={styles.barButton}>
                        <Text
                            style={{...styles.barButtonText, ...(activeMonth == 2 ? {color: '#FFF'} : {})}}>2{'\n'}{translations.general.months}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        fetchAnalysis(3);
                    }} style={styles.barButton}>
                        <Text
                            style={{...styles.barButtonText, ...(activeMonth == 3 ? {color: '#FFF'} : {})}}>3{'\n'}{translations.general.months}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        fetchAnalysis(6);
                    }} style={styles.barButton}>
                        <Text
                            style={{...styles.barButtonText, ...(activeMonth == 6 ? {color: '#FFF'} : {})}}>6{'\n'}{translations.general.months}</Text>
                    </TouchableOpacity>
                    <TouchableOpacity onPress={() => {
                        fetchAnalysis(12);
                    }} style={styles.barButton}>
                        <Text
                            style={{...styles.barButtonText, ...(activeMonth == 12 ? {color: '#FFF'} : {})}}>12{'\n'}{translations.general.months}</Text>
                    </TouchableOpacity>
                </View>
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
        user: state.UserReducer,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PastAnalysis);

const styles = {
    middleContainer: {
        backgroundColor: Colors.mainBackground,
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        marginTop: platformSpecific.containerVerticalMargin,
        width: 100 + '%',
    },
    heading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: 22,
        textAlign: 'center',
    },
    analysisResultText: {
        width: Dimensions.get('window').width * 0.375,
        marginTop: 5,
        position: 'absolute',
        top: Dimensions.get('window').width * 0.680,
        fontSize: 18,
        fontFamily: 'Montserrat_600SemiBold',
        color: '#FFF',
        textAlign: 'center',
    },
    hr: {
        width: 70 + '%',
        borderWidth: 1,
        borderColor: '#FFF',
        marginVertical: 20,
    },
    resultHeading: {
        fontSize: 24,
        fontFamily: 'Montserrat_600SemiBold',
        color: '#FFF',
        width: 80 + '%',
        textAlign: 'center',
    },
    button: {
        width: 48 + '%',
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 100 + '%',
        marginVertical: 20,
    },
    barWrapper: {
        width: 100 + '%',
        padding: 20,
    },
    bar: {
        width: 100 + '%',
        height: 45,
        backgroundColor: '#FFF',
    },
    barButtonsWrapper: {
        flexDirection: 'row',
    },
    barButton: {
        flex: 1,
    },
    barButtonText: {
        textAlign: 'center',
        color: '#000',
        top: 10,
        fontFamily: 'Montserrat_600SemiBold',
    },
    noAnalysis: {
        padding: 20,
        fontSize: 22,
        color: '#FFF',
    },
};
