import React, {useState} from 'react';
import {
    ActivityIndicator,
    Alert,
    Dimensions,
    Platform,
    ScrollView,
    Text,
    TextInput,
    View
} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import {connect} from 'react-redux';
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";
import Space from "../components/Space";
import * as firebase from 'firebase';

import 'firebase/auth';
import {setUser} from "../redux/actions/UserActions";

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function AddMember(props) {
    const [name, setName] = useState('');
    const [nickname, setNickname] = useState('');
    const [isInProcess, setIsInProcess] = useState(false);

    const translations = props.translations[props.translations.currentLanguage];
    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton/>)}
                headerCenter={() => (<></>)}
                headerRight={() => (<LanguageChangeButton/>)}
            />
            <View style={styles.container}>
                <Text style={styles.pageHeading}>{translations.addMember.heading.addMember}</Text>
                <Space />
                <TextInput
                    style={styles.textInput}
                    onChangeText={setName}
                    placeholder={translations.addMember.name}
                />
                <TextInput
                    style={styles.textInput}
                    onChangeText={setNickname}
                    placeholder={translations.addMember.nickName}
                />
                {
                    isInProcess ?
                    <ActivityIndicator size={"large"} color={Colors.primary} /> :
                    <Button
                        label={translations.general.add} wrapperStyle={{width: 100 + '%'}}
                        onPress={() => {
                            if (props.user.data !== {} && props.user.data.user.uid) {
                                if (name === '' || nickname === '') {
                                    Alert.alert(translations.general.warning, translations.general.fillAll);
                                } else {
                                    setIsInProcess(true);
                                    firebase.database().ref('/users/' + props.user.data.user.uid + '/pets').push({
                                        name,
                                        nickname,
                                        createdAt: Date.now(),
                                    }).then(data => {
                                        let members = props.user.pets;
                                        if (members === null) {
                                            members = [];
                                        }
                                        console.log(data);
                                        const splittedData = String(data).split('/');
                                        const id = splittedData[splittedData.length - 1];
                                        members.push({
                                            id,
                                            name,
                                            nickname,
                                            createdAt: Date.now(),
                                        });
                                        props.setUser({pets: members});
                                        setName('');
                                        setNickname('');
                                        props.navigation.navigate('Landing');
                                    }).catch(err => {
                                        console.log(err);
                                        alert('Error occured while inserting your data to database. Please try again later. Technical details:' + err);
                                    }).finally(() => {
                                        setIsInProcess(false);
                                    });
                                }
                            }
                        }}
                    />
                }
                <Space />
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
    };
}

const mapDispatchToProps = dispatch => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddMember);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    pageHeading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: 24,
        textAlign: 'center',
    },
    textInput: {
        width: 100 + '%',
        height: 61,
        backgroundColor: '#FFF',
        borderColor: '#DDD',
        borderTopWidth: 3,
        padding: 10,
        marginBottom: 26,
        textAlign: 'center',
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: 22,
        color: '#989898',
    },
};
