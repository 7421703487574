import React from 'react';
import Button from '../Button';
import { Image } from 'react-native';
import { connect } from 'react-redux';
import {setTranslations} from "../../redux/actions/TranslateActions";
import StyleGuide from "../../../res/constants/StyleGuide";

function LanguageChangeButton(props) {
    return (
        <Button
            onPress={() => {
                let newLang = 'tr';
                if (props.translations.currentLanguage === 'tr') {
                    newLang = 'en';
                } else if (props.translations.currentLanguage === 'en') {
                    newLang = 'de';
                }
                props.setTranslations({currentLanguage: newLang});
            }}
            label={props.translations.currentLanguage.toUpperCase()}
            wrapperStyle={styles.buttonWrapper}
            style={styles.buttonText}
            beforeText={<Image source={require('../../../res/images/language-icon.png')} style={{width: 45, height: 45, marginRight: 5,}} />}
        />
    );
}

const mapStateToProps = state => {
    return {
        translations: state.TranslateReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setTranslations: (payload) => dispatch(setTranslations(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LanguageChangeButton);

const styles = {
    buttonWrapper: {
        width: 30 + '%',
        height: StyleGuide.buttons.height,
    },
    buttonText: {
        fontSize: StyleGuide.buttons.fontSize,
    },
};
