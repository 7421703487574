import React, {useState} from 'react';
import {ActivityIndicator, Dimensions, Platform, ScrollView, TextInput, View} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import { connect } from 'react-redux';
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";
import { navigate } from "../RootNavigation";
import {setUser} from "../redux/actions/UserActions";
// import Toast from "react-native-toast-message";
import * as firebase from 'firebase';

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function FreeAdvising(props) {
    const translations = props.translations[props.translations.currentLanguage];
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [name, setName] = useState('');
    const [surname, setSurname] = useState('');
    const [city, setCity] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton realBack={true} />)}
                headerCenter={() => (<></>)}
                headerRight={() => (<LanguageChangeButton />)}
            />
            <View style={styles.container}>
                <TextInput
                    style={styles.textInput}
                    onChangeText={setEmail}
                    value={email}
                    keyboardType={"email-address"}
                    placeholder={translations.placeholders.email}
                />
                <TextInput
                    style={styles.textInput}
                    onChangeText={setPhone}
                    value={phone}
                    keyboardType={"number-pad"}
                    placeholder={translations.placeholders.phone}
                />
                <TextInput
                    style={styles.textInput}
                    onChangeText={setName}
                    value={name}
                    placeholder={translations.placeholders.name}
                />
                <TextInput
                    style={styles.textInput}
                    onChangeText={setSurname}
                    value={surname}
                    placeholder={translations.placeholders.surname}
                />
                <TextInput
                    style={styles.textInput}
                    onChangeText={setCity}
                    value={city}
                    placeholder={translations.placeholders.city}
                />
                {isLoading ? <ActivityIndicator color={Colors.primary} size={'large'} /> :
                <Button label={translations.general.sendRequest} style={{fontSize: 22}} wrapperStyle={{width: 100 + '%', paddingVertical: 10,}} onPress={() => {
                    if (!email || !phone || !name || !surname || !city) {
                        // Toast.show({
                        //     type: 'error',
                        //     visibilityTime: 1000,
                        //     position: 'bottom',
                        //     text1: 'Please fill all fields',
                        // })
                    } else {
                        // Check if email valid
                        const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                        if (!re.test(email)) {
                            // Toast.show({
                            //     type: 'error',
                            //     visibilityTime: 1000,
                            //     position: 'bottom',
                            //     text1: translations.errors.emailNotValid,
                            // })
                        } else if (phone.length < 9 || phone.length > 13) {
                            // Toast.show({
                            //     type: 'error',
                            //     visibilityTime: 1000,
                            //     position: 'bottom',
                            //     text1: translations.errors.phoneNotValid,
                            // })
                        } else {
                            setIsLoading(true);
                            firebase.database().ref('messages').push({
                                email,
                                phone,
                                name,
                                surname,
                                city,
                                hasAnswered: false,
                            }).then(addedUrl => {
                                setEmail('');
                                setPhone('');
                                setName('');
                                setSurname('');
                                setCity('');
                                // Toast.show({
                                //     type: 'success',
                                //     position: 'bottom',
                                //     text1: translations.freeAdvising.thankYou,
                                //     text2: translations.freeAdvising.thankYouDesc,
                                // })
                                navigate('Landing');
                            }).catch(error => {
                                // Toast.show({
                                //     type: 'error',
                                //     position: 'bottom',
                                //     text1: translations.general.errorOccurred,
                                //     text2: translations.errors.tryAgain,
                                // })
                            }).finally(() => {
                                setIsLoading(false);
                            });
                        }
                    }
                }} />}
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FreeAdvising);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    textInput: {
        width: 100 + '%',
        height: 50,
        backgroundColor: '#FFF',
        borderColor: '#DDD',
        borderTopWidth: 3,
        padding: 10,
        marginBottom: 15,
        textAlign: 'center',
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: 18,
        color: '#989898',
    },
};
