import React, {useState} from 'react';
import {ActivityIndicator, Alert, Dimensions, Platform, ScrollView, Text, TextInput, TouchableOpacity, View} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import { connect } from 'react-redux';
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";
import Space from "../components/Space";
import * as firebase from 'firebase';
import * as SecureStore from "expo-secure-store";

import 'firebase/auth';
import {setUser} from "../redux/actions/UserActions";
import AutoHeightImage from "react-native-auto-height-image";
import StyleGuide from "../../res/constants/StyleGuide";

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function Login(props) {
    const translations = props.translations[props.translations.currentLanguage];
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isInProcess, setIsInProcess] = useState(false);

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton />)}
                headerCenter={() => (<></>)}
                headerRight={() => (<LanguageChangeButton />)}
            />
            {
                props.user.isLoggedIn ? <Text style={{
                    padding: 20,
                    fontSize: 24,
                    color: '#FFF',
                    fontFamily: 'Montserrat_600SemiBold',
                    }}>You are already signed in. Please continue with the home screen.</Text> :
                <View style={styles.container}>
                    <AutoHeightImage width={Dimensions.get('window').width * 0.3} source={require('../../res/images/logo.png')} />
                    <Text style={styles.heading}>{translations.landing.heading}</Text>
                    <Text style={styles.secondaryHeading}>{translations.landing.secondaryHeading}</Text>
                    <Space height={50} />
                    <TextInput
                        style={styles.textInput}
                        onChangeText={setEmail}
                        keyboardType={"email-address"}
                        placeholder={"example@gmail.com"}
                    />
                    <TextInput
                        style={styles.textInput}
                        onChangeText={setPassword}
                        secureTextEntry={true}
                        placeholder={"password"}
                    />
                    {
                        isInProcess ?
                        <ActivityIndicator size={'large'} color={Colors.primary} /> :
                        <Button label={translations.general.loginButton} wrapperStyle={{width: 100 + '%'}} onPress={() => {
                            // Login
                            if (email === '' || password === '') {
                                Alert.alert(translations.general.warning, translations.general.fillAll);
                            } else {
                                setIsInProcess(true);
                                firebase.auth().signInWithEmailAndPassword(email, password).then(user => {
                                    if (Platform.OS === "web") {
                                        console.log("WEB");
                                        localStorage.setItem("userData", JSON.stringify(user));
                                        firebase.database().ref('users/' + user.user.uid).once('value').then(snapshot => {
                                            let isActive = false;
                                            if (null != snapshot.val()) {
                                                isActive = snapshot.val().isActive
                                            }
                                            props.setUser({
                                                isLoggedIn: true,
                                                data: user,
                                                isActive,
                                            });

                                            const val = snapshot.val()
                                            var role = 'user'
                                            var fullname = ''
                                            if (val) {
                                              role = val.role ? val.role : 'user'
                                              fullname = val.fullname ? val.fullname : 'user'
                                            }
                                            localStorage.setItem('firebase:userUid', user.user.uid)
                                            localStorage.setItem('firebase:userRole', role)
                                            localStorage.setItem('firebase:userName', fullname)
					    localStorage.setItem('firebase:authUser:AIzaSyBhwDonz7ld-ORI64-IVizegMYSPGN0nr4\n:[DEFAULT]', JSON.stringify(user.user))
					    window.location.href = '/';

                                            props.navigation.navigate('Landing');
                                        }).catch(err => {
                                            console.log("LOGIN FETCH DATA ERROR", err);
                                        });
                                    }
                                }).catch(err => {
                                    alert('Error Occured: ' + err.message);
                                }).finally(() => {
                                    setIsInProcess(false);
                                });
                            }
                        }} />
                    }
                    <TouchableOpacity onPress={() => {
                        props.navigation.navigate('ForgotPassword');
                    }}>
                        <Text style={{
                            fontSize: 14,
                            fontFamily: 'Montserrat_600SemiBold',
                            color: "#FFFFFF",
                            marginVertical: 14,
                        }}>
                            {translations.login.forgotPassword}
                        </Text>
                    </TouchableOpacity>
                    <Button label={translations.general.signUpButton} wrapperStyle={{width: 100 + '%'}} onPress={() => {
                        props.navigation.navigate('SignUp');
                    }} />
                    <Space />
                </View>
            }
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translations: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    heading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: StyleGuide.fontSize.huge,
        textAlign: 'center',
    },
    secondaryHeading: {
        color: Colors.light,
        fontFamily: 'Montserrat_800ExtraBold',
        fontSize: StyleGuide.fontSize.secondaryHeading,
        textAlign: 'center',
    },
    textInput: {
        width: 100 + '%',
        height: 61,
        backgroundColor: '#FFF',
        borderColor: '#DDD',
        borderTopWidth: 3,
        padding: 10,
        marginBottom: 26,
        textAlign: 'center',
        fontFamily: 'Montserrat_600SemiBold',
        fontSize: 22,
        color: '#989898',
    },
};
