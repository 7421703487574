import {Text} from "react-native";
import React from "react";

const INITIAL_STATE = {
    tr: {
        landing: {
            heading: 'NITT',
            secondaryHeading: 'Doğal Bağışıklık\nIsı Testi',
            sloganHeading: 'NO HEAT NO LIFE',
            sloganDescription: 'Doğada her şey sıcaklık ile düzenlenir.',
            sloganButton: 'DETAYLAR',
            startTestButton: 'TESTE BAŞLA',
            addMember: 'AİLE ÜYESİ EKLE',
            addPet: 'EVCİL HAYVAN EKLE',
            me: 'Ben',
            userPastData: 'GEÇMİŞ VERİLERİNİZİN\nANALİZLERİ',
            memberPastData: 'AİLE ÜYELERİNİN\nANALİZLERİ',
            familyMembers: 'AİLE ÜYELERİ',
            pets: 'EVCİL AİLE HAYVANLARI',
            earTesting: 'OPSİYONEL KULAK TESTİ',
            panel: 'DASHBOARD',
            renewPackage: 'PROGRAMINIZI YENİLEYİN',
            renewProgram: 'Programınız sona erdi. Lütfen programınızı yenileyin.',
        },
        points: {
            rightNeck: "Sağ Boyun",
            leftNeck: "Sol Boyun",
            rightNape: "Sağ Ense",
            leftNape: "Sol Ense",
            rightBelly: "Sağ Karın",
            leftBelly: "Sol Karın",
            rightBack: "Sağ Bel",
            leftBack: "Sol Bel",
            rightHandIn: "Sağ Avuç İçi",
            leftHandIn: "Sol Avuç İçi",
            rightHandOut: "Sağ El Üstü",
            leftHandOut: "Sol El Üstü",
            rightFootUp: "Sağ Ayak Üstü",
            leftFootUp: "Sol Ayak Üstü",
            rightFootDown: "Sağ Ayak Altı",
            leftFootDown: "Sol Ayak Altı",
            rightEar: "Sağ Kulak",
            leftEar: "Sol Kulak",
            pet: {
                tongue: 'Dil Üstü',
                frontRightPaw: 'Ön Pati Sağ',
                frontLeftPaw: 'Ön Pati Sol',
                backRightPaw: 'Arka Pati Sağ',
                backLeftPaw: 'Arka Pati Sol',
            }
        },
        advising: {
            freeAdvising: 'Ücretsiz Danışmanlık',
            warning: 'Bu özellik sadece aktif üyeler içindir. Üyeliğinizi aktifleştirmek isterseniz, lütfen "ÜCRETSİZ DANIŞMANLIK" talep edin.',
        },
        addMember: {
            name: "Ad",
            nickName: "Takma Ad",
            heading: {
                addMember: 'AİLE ÜYESİ EKLE',
            },
        },
        beforeStart: {
            description: "Test öncesi temassız, kızılötesi termometre gerekecektir. (herhangi bir marka olabilir - lütfen termometrenin 34°C / 86°F sıcaklığa kadar ölçtüğünden emin olun.)",
            instructions: "BELİRTİLMİŞ ÇEŞİTLİ NOKTALARDAN ÖLÇÜM YAPIP VÜCUT ISILARINIZI SİSTEME GİRİN",
            goAhead: "BAŞLA",
        },
        general: {
            loginButton: "GİRİŞ YAP",
            signUpButton: "KAYIT OL",
            resetPasswordButton: "SIFIRLA",
            logout: 'ÇIKIŞ YAP',
            add: 'EKLE',
            next: "DEVAM",
            menu: "MENÜ",
            warning: "Uyarı",
            passwordMatchError: 'Girdiğiniz parolalar eşleşmiyor.',
            errorOccurred: 'Hata Oluştu',
            fillAll: 'Lütfen tüm alanları doldurun.',
            sendRequest: "İSTEK GÖNDER",
            shareMessage: 'Apple Store veya Google Play Store\'dan NITT App\'i indirin!\nhttps://www.naturallaw.ch/nitt_app/',
            months: 'Ay',
            month: 'Ay',
        },
        measure: {
            instructions: "LÜTFEN GÖSTERİLDİĞİ ŞEKİLDE ÖLÇÜN",
            petTongue: 'Evcil hayvanınız dil üzerinde ölçüme izin vermiyorsa, lütfen uygulamada "Low" ayarını seçin ve pati ölçümlerine devam edin.',
        },
        menu: {
            membersLogin: 'Üye Girişi',
            freeSupport: 'Ücretsiz MFT-Danışmanlık Desteği',
            noHeatNoLive: 'NO HEAT - NO LIVE',
            legalPoints: 'Yasal Detaylar',
            consultantLogin: 'Danışman Girişi',
        },
        analysis: {
            heading: "NITT ANALİZ",
            leftSide: "SOL TARAF DOĞAL BAĞIŞIKLIĞINIZ \n%s%",
            rightSide: "SAĞ TARAF DOĞAL BAĞIŞIKLIĞINIZ \n%s%",
            overAll: "TÜM VÜCUT DOĞAL BAĞIŞIKLIĞINIZ %s%",
            saveResults: "SONUCU KAYDET",
            moreInfo: "SONUÇ HAKKINDA DETAYLAR",
            analysisFor: "%s İÇİN ANALİZLER",
            pet: {
                heading: "NITT ANALİZ",
                leftSide: "SOL TARAF DOĞAL BAĞIŞIKLIĞI \n%s%",
                rightSide: "SAĞ TARAF DOĞAL BAĞIŞIKLIĞI \n%s%",
                overAll: "EVCİL HAYVANIN TÜM VÜCUT DOĞAL BAĞIŞIKLIĞI %s%",
                saveResults: "SONUCU KAYDET",
                moreInfo: "SONUÇ HAKKINDA DETAYLAR",
                analysisFor: "%s İÇİN ANALİZLER",
            }
        },
        suggestion: {
            heading: "NITT\nANALİZ HAKKINDA\nDAHA FAZLA BİLGİ",
            freeAdvising: 'ÜCRETSİZ\nDANIŞMANLIK',
            preventionKits: 'ÖNLEM\nKİTLERİ',
            // TURKCE
            content1: 'DOĞAL İMMÜN SİSTEMİNİZ İYİDİR.\nLütfen sağlığınıza dikkat etmeye devam edin',
            content2: 'Doğal bağışıklık sisteminiz -%s% oranında zayıflamışsa, günlük yaşamınız hafif rahatsızlıklardan etkilenebilir. Bu durumda kimyasız ve yan etkisiz MFT-Önlem BASIC Programı kiti ile, vücut ısınızı yeniden dengelemenizi tavsiye ederiz.',
            content3: 'Cilt tahrişleri ve hızlı cilt yaşlanması (kırışıklıklar, kuru cilt ...) ile karşı karşıya kalırsanız, size kimyasal ve yan etkiler içermeyen bir MFT-Prevention Advance Beauty Program kitini öneririz.',
            content4: 'Doğal bağışıklık sisteminiz -%s% oranında zayıflamışsa, günlük yaşamınız ilerlemiş rahatsızlıklardan etkilenebilir.\n\n' +
                'Natural Law\'a göre, fasya bağ dokusundaki biyolojik hasar nedeniyle vücut ısısı önemli ölçüde değişebilir. Elektronların vücuttan hızlı geçişi yavaşladıkça, sıcaklık 36° C\'nin altına düşer.\n\n' +
                'Bu durumda kimyasız ve yan etkisiz MFT-Önlem ADVANCED Programı kiti ile, vücut ısınızı yeniden dengelemenizi tavsiye ederiz.',
            content5: 'Doğal bağışıklık sisteminiz -%s% oranında zayıflamışsa, günlük yaşamınız kronik rahatsızlıklardan etkilenebilir.\n\n' +
                'Natural Law\'a göre, fasya bağ dokusundaki biyolojik hasar nedeniyle vücut ısısı önemli ölçüde değişebilir. Elektronların vücuttan hızlı geçişi yavaşladıkça, sıcaklık 35° C\'nin altına düşer.\n\n' +
                'Bu durumda kimyasız ve yan etkisiz MFT-Önlem CHRONIC Programı kiti ile, vücut ısınızı yeniden dengelemenizi tavsiye ederiz.',
        },
        placeholders: {
            email: "ornek@gmail.com",
            password: "parola",
            passwordAgain: "parola yeniden",
            phone: "telefon numarası",
            name: "ad",
            surname: "soyad",
            city: "şehir",
        },
        freeAdvising: {
            thankYou: 'Mesajınız için teşekkür ederiz.',
            thankYouDesc: 'Size en kısa zamanda ulaşacağız.',
        },
        errors: {
            emailNotValid: 'Girdiğiniz email adresi geçersiz.',
            phoneNotValid: 'Girdiğiniz telefon numarası geçersiz.',
            tryAgain: 'Lütfen daha sonra tekrar deneyin.',
            noData: 'Bu aile üyesi için daha önce hiç ölçüm yapılmamış.',
        },
        legalPoints: {
            heading: 'YASAL DETAYLAR',
            content: 'Dikkatinizi, sıcaklık okumalarının üreticinin test ekipmanlarına ve algoritmalarına bağlı olarak değişebileceği gerçeğine çekiyoruz. Uygun bir değerlendirme yapabilmek için hesaplamalarımızda belirli parametreleri dikkate aldık.\n\nNITT, İsviçre\'nin Zürih şehrinde Natural Law Scientific Systems tarafından geliştirilmiş bir teşhis aracıdır. Bu, tüm biyolojik sistemlerin, yani bitki örtüsü, hayvanlar ve insanların, kendi kendini düzenleyen ısıtma sistemleriyle doğal bağışıklığı düzenlediği gerçeğine dayanmaktadır.\n\nNITT servisi, tüm sorumluluklar dışında ve herhangi bir garantinin hariç tutulmasıyla gerçekleşir. NITT hizmeti tıbbi, psikiyatrik veya psikolojik tedavi teşkil etmez. Sunulan hizmetler kapsamında teşhis konulmamakta ve şifa vaadi verilmemektedir. NITT servisi gerekli bir tedaviyi doktorla değiştiremez.',
        },
        login: {
            dontHaveAccount: 'Hesabınız Yok Mu?',
            signUp: 'Üye Olun!',
            forgotPassword: 'Parolamı Unuttum',
            or: 'veya',
        },
        header: {
            familyAccount: 'AİLE\nHESABI',
            menu: 'MENÜ',
            goBack: 'GERİ DÖN',
        },
        measurePoints: {
            left: '(Sol) ',
            right: '(Sağ) ',
            neck: 'Boyun',
            nape: 'Ense',
            back: 'Bel',
            handIn: 'Avuç İçi',
            handOut: 'Avuş Dışı',
            belly: 'Göbek',
            footUp: 'Ayak Üstü',
            footDown: 'Ayak Altı',
            rightSide: 'Vücudunuzun Sağ Tarafı',
            leftSide: 'Vücudununuzun Sol Tarafı',
            description: 'İşte uygulamadaki ölçüm verileriniz.',
            ear: 'Kulak',
        },
    },
    en: {
        landing: {
            heading: 'NITT',
            secondaryHeading: 'Natural Immunity\nTemperature Testing',
            sloganHeading: 'NO HEAT NO LIFE',
            sloganDescription: 'In nature everything is regulated by temperature.',
            sloganButton: 'SEE MORE',
            startTestButton: 'START TEST',
            addMember: 'ADD MEMBER',
            addPet: 'ADD PET',
            me: 'Me',
            userPastData: 'ANALYSIS OF YOUR\nPAST DATA',
            memberPastData: 'FAMILY MEMBER\'S\nPAST DATA',
            familyMembers: 'FAMILY MEMBERS',
            pets: 'PET MEMBERS',
            earTesting: 'OPTIONAL EAR TESTING',
            panel: 'DASHBOARD',
            renewPackage: 'RENEW PROGRAM',
            renewProgram: 'Your Program Has Over. Please Renew Your Program.',
        },
        points: {
            rightNeck: "Right Neck",
            leftNeck: "Left Neck",
            rightNape: "Right Nape",
            leftNape: "Left Nape",
            rightBelly: "Right Belly",
            leftBelly: "Left Belly",
            rightBack: "Right Back",
            leftBack: "Left Back",
            rightHandIn: "Right Hand In",
            leftHandIn: "Left Hand In",
            rightHandOut: "Right Hand Out",
            leftHandOut: "Left Hand Out",
            rightFootUp: "Right Foot Up",
            leftFootUp: "Left Foot Up",
            rightFootDown: "Right Foot Down",
            leftFootDown: "Left Foot Down",
            rightEar: "Right Ear",
            leftEar: "Left Ear",
            pet: {
                tongue: 'Tongue',
                frontRightPaw: 'Paw Front Right',
                frontLeftPaw: 'Paw Front Left',
                backRightPaw: 'Paw Rear Right',
                backLeftPaw: 'Paw Rear Left',
            }
        },
        advising: {
            freeAdvising: 'Free Advising',
            warning: 'This function is available only for active members. If you want to get an active membership, please ask for "FREE ADVISING"',
        },
        addMember: {
            name: "Name",
            nickName: "Nickname",
            heading: {
                addMember: 'ADD FAMILY MEMBER',
            },
        },
        beforeStart: {
            description: "Prior testing you would need one of these, Non-contact, infrared Thermometers.\n(can be any trademark - please make sure that the thermometer can measure up to 34°C / 86°F)",
            instructions: "MEASURE FROM VARIOUS POINTS AND ENTER YOUR BODY TEMPERATURES",
            goAhead: "GO AHEAD",
        },
        general: {
            loginButton: "LOGIN",
            signUpButton: "SIGN UP",
            resetPasswordButton: "RESET",
            logout: 'LOG OUT',
            add: 'ADD',
            next: "NEXT",
            menu: "MORE",
            warning: "Warning",
            passwordMatchError: 'Password does not match.',
            errorOccurred: 'Error Occurred',
            fillAll: 'Please fill all fields.',
            sendRequest: "SEND REQUEST",
            shareMessage: 'Download NITT App from Apple Store or Google Play Store to test your Natural Immunity!\nhttps://www.naturallaw.ch/nitt_app/',
            months: 'Months',
            month: 'Month',
        },
        measure: {
            instructions: "PLEASE MEASURE AS INDICATED",
            petTongue: 'If your Pet does not allow the measurement on the tongue, please set "Low" on the app and go to the paw measurements.',
        },
        menu: {
            membersLogin: 'Members Login',
            freeSupport: 'Free MFT-Advisor Support',
            noHeatNoLive: 'NO HEAT - NO LIVE',
            legalPoints: 'Legal Points',
            consultantLogin: 'Consultant Login',
        },
        analysis: {
            heading: "NITT ANALYSIS",
            leftSide: "LEFT SIDE NATURAL IMMUNITY SHOWS\n%s%",
            rightSide: "RIGHT SIDE NATURAL IMMUNITY SHOWS\n%s%",
            overAll: "YOUR OVERALL NATURAL IMMUNITY SHOWS %s%",
            saveResults: "SAVE MY RESULTS",
            moreInfo: "MORE INFO ON RESULTS",
            analysisFor: "NITT ANALYSIS FOR: %s",
            pet: {
                heading: "NITT ANALYSIS",
                leftSide: "LEFT SIDE NATURAL IMMUNITY SHOWS\n%s%",
                rightSide: "RIGHT SIDE NATURAL IMMUNITY SHOWS\n%s%",
                overAll: "PET'S OVERALL NATURAL IMMUNITY SHOWS %s%",
                saveResults: "SAVE MY RESULTS",
                moreInfo: "MORE INFO ON RESULTS",
                analysisFor: "NITT ANALYSIS FOR: %s",
            }
        },
        suggestion: {
            heading: "NITT\nMORE INFORMATION\nON ANALYSIS",
            freeAdvising: 'FREE\nADVISING',
            preventionKits: 'PREVENTION\nKITS',
            content1: 'YOU ARE FINE\nPlease continue to take care of your health.',
            content2: 'If your natural immune system is weakened by -%s% and your daily life can be affected by light disorders;\nWe recommend you for rebalancing your body temperature, an MFT-Prevention BASIC Program kit, without chemicals and side affects.',
            content3: 'If you are confronted with skin irritations and rapid skin aging (wrinkles, dry skin..), we recommend you an MFT-Prevention Advance Beauty Program kit, without chemicals and side affects.',
            content4: 'If your natural immune system is weakened by -%s% your daily life can be affected by advanced disorders!\n\nAccording to Natural Law, the the body temperature can vary significantly due to a biological facial connective-tissue damage. If the fast electron passage from the body slows down, its temperature drops below 36°C degrees.\n\nWe recommend you for rebalancing your body temperature, an MFT-Prevention ADVANCED Program kit, without chemicals and side affects.',
            content5: 'If your natural immune system is weakened by -%s% your daily life can be affected by chronic disorders!\n\nAccording to Natural Law, the the body temperature can vary significantly due to a biological facial connective-tissue damage. If the fast electron passage from the body slows down, its temperature drops below 35°C degrees.\n\nWe recommend you for rebalancing your body temperature, an MFT-Prevention CHRONIC Program kit, without chemicals and side affects.',
        },
        placeholders: {
            email: "example@gmail.com",
            password: "password",
            passwordAgain: "password again",
            phone: "phone number",
            name: "name",
            surname: "surname",
            city: "city",
        },
        freeAdvising: {
            thankYou: 'Thank you for your message.',
            thankYouDesc: 'We will get back to you soon.',
        },
        errors: {
            emailNotValid: 'Entered email address is not valid',
            phoneNotValid: 'Entered phone number is not valid',
            tryAgain: 'Please try again later.',
            noData: 'We have no analysis data related to selected family member.',
        },
        legalPoints: {
            heading: 'LEGAL POINTS',
            content: 'We draw your attention to the fact that the temperature readings can vary depending on the manufacturer testing equipments and algorithms. We have taken certain parameters into account in our calculations in order to make an appropriate assessment.\n\nNITT is a diagnosis Tool developed by Natural Law Scientific Systems in Zurich, Switzerland.  It’s based on the fact, that all biological systems, i.e. vegetation, animals and humans, are regulating natural immunity by their self-regulating heating system.\n\nNITT service take place under exclusion of all liability and under exclusion of any warranty. NITT service do not constitute medical, psychiatric or psychological treatment. Within the scope of the offered services, no diagnoses are made and no promises of healing are given. NITT service can not replace a necessary treatment with a doctor.',
        },
        login: {
            dontHaveAccount: 'Don\'t you have an account?',
            signUp: 'Sign Up!',
            forgotPassword: 'Forgot Password?',
            or: 'or',
        },
        header: {
            familyAccount: 'FAMILY\nACCOUNT',
            menu: 'MORE',
            goBack: 'GO BACK',
        },
        measurePoints: {
            left: '(Left) ',
            right: '(Right) ',
            neck: 'Neck',
            nape: 'Nape',
            back: 'Back',
            handIn: 'Hand In',
            handOut: 'Hand Out',
            belly: 'Belly',
            footUp: 'Foot Up',
            footDown: 'Foot Down',
            rightSide: 'Right Side of the Body',
            leftSide: 'Left Side of the Body',
            description: 'Here we go with your measured data of the app.',
            ear: 'Ear',
        },
    },
    de: {
        landing: {
            heading: 'NITT',
            secondaryHeading: 'Natural Immunity\nTemperature Testing',
            sloganHeading: 'NO HEAT NO LIFE',
            sloganDescription: 'In der Natur wird alles über die Temperatur reguliert.',
            sloganButton: 'MEHR INFO',
            startTestButton: 'START TEST',
            addMember: 'MITGLIED HINZUFÜGEN',
            addPet: 'HAUSTIER HINZUFÜGEN',
            me: 'Ich',
            userPastData: 'ANALYSE IHRER\nHISTORIE DATEN',
            memberPastData: 'DATENAUSWERTUNG\nDES MITGLIEDES',
            familyMembers: 'FAMILIEN MITGLIEDER',
            pets: 'PET MEMBERS',
            earTesting: 'OHRTEST OPTIONAL',
            panel: 'DASHBOARD',
            renewPackage: 'RENEW PROGRAM',
            renewProgram: 'Ihr Programm ist beendet. Bitte erneuern Sie Ihr Programm.',
        },
        points: {
            rightNeck: "Rechter Hals",
            leftNeck: "Linker Hals",
            rightNape: "Rechter Nacken",
            leftNape: "Linker Nacken",
            rightBelly: "Rechter Bauch",
            leftBelly: "Linker Bauch",
            rightBack: "Rechter Kreuz",
            leftBack: "Linker Kreuz",
            rightHandIn: "Rechte Hand innen",
            leftHandIn: "Linke Hand innen",
            rightHandOut: "Rechte Hand aussen",
            leftHandOut: "Linke Hand aussen",
            rightFootUp: "Rechter Fuss oben",
            leftFootUp: "Linker Fuss oben",
            rightFootDown: "Rechter Fuss unter",
            leftFootDown: "Linker Fuss unter",
            rightEar: "Rechtes Ohr",
            leftEar: "Linkes Ohr",
            pet: {
                tongue: 'Zunge',
                frontRightPaw: 'Pfoten vorne rechts',
                frontLeftPaw: 'Pfoten vorne links',
                backRightPaw: 'Pfoten hinten rechts',
                backLeftPaw: 'Pfoten hinten links',
            }
        },
        advising: {
            freeAdvising: 'Kostenlose Beratung',
            warning: 'Diese Funktion ist nur für aktive Mitglieder verfügbar. Wenn Sie eine aktive Mitgliedschaft erhalten möchten, fragen Sie bitte nach einer "KOSTENLOSER BERATUNG".',
        },
        addMember: {
            name: "Name",
            nickName: "Spitzname",
            heading: {
                addMember: 'FAMILIENMITGLIED HINZUFÜGEN',
            },
        },
        beforeStart: {
            description: "Vor dem Testen benötigen Sie eines dieser berührungslosen Infrarot-Thermometer.\n(kann eine beliebige Marke sein - stellen Sie bitte bisicher, dass das Thermometer bis zu 34°C / 86F messen kann).",
            instructions: "MESSEN SIE BITTE DIE VERSCHIEDENEN PUNKTE UND GEBEN SIE IHRE KÖRPERTEMPERATUREN EIN.",
            goAhead: "WEITER",
        },
        general: {
            loginButton: "LOGIN",
            signUpButton: "SIGN UP",
            resetPasswordButton: "RESET",
            logout: 'LOG OUT',
            add: 'HINZUFÜGEN',
            next: "NEXT",
            menu: "MEHR",
            warning: "Warning",
            passwordMatchError: 'Passwort stimmt nicht überein.',
            errorOccurred: 'Ein Fehler ist aufgetreten',
            fillAll: 'Bitte alle Felder ausfüllen.',
            sendRequest: "ANFRAGE SENDEN",
            shareMessage: 'Laden Sie die NITT-App aus dem Apple Store oder Google Play Store herunter, um Ihre natürliche Immunität zu testen!\nhttps: //www.naturallaw.ch/nitt_app/',
            months: 'Monate',
            month: 'Monat',
        },
        measure: {
            instructions: "BITTE MESSEN SIE WIE ANGEZEIGT",
            petTongue: "Wenn Ihr Pet die Messung auf der Zunge nicht zulässt, stellen Sie in der App die \"Low\" Messung ein und gehen Sie zu den Pfotenmessungen."
        },
        menu: {
            membersLogin: 'Mitglieder Login',
            freeSupport: 'Kostenlose MFT-Beratung',
            noHeatNoLive: 'NO HEAT - NO LIVE',
            legalPoints: 'Haftungsauschluss',
            consultantLogin: 'Berater Login',
        },
        analysis: {
            heading: "NITT ANALYSE",
            leftSide: "NATÜRLICHE IMMUNITÄT AUF DER LINKEN KÖRPERHÄLFTE ZEIGT\n%s%",
            rightSide: "NATÜRLICHE IMMUNITÄT AUF DER RECHTEN KÖRPERHÄLFTE ZEIGT\n%s%",
            overAll: "IHRE GESAMTE NATÜRLICHE IMMUNITÄT ZEIGT %s%",
            saveResults: "MESSDATEN SPEICHERN",
            moreInfo: "MEHR INFO ZU DEN ANALYSEN",
            analysisFor: "NITT ANALYSE FÜR: %s",
            pet: {
                heading: "NITT ANALYSE",
                leftSide: "NATÜRLICHE IMMUNITÄT AUF DER LINKEN KÖRPERHÄLFTE ZEIGT\n%s%",
                rightSide: "NATÜRLICHE IMMUNITÄT AUF DER RECHTEN KÖRPERHÄLFTE ZEIGT\n%s%",
                overAll: "PETS NATÜRLICHE IMMUNITÄT ZEIGT %s%",
                saveResults: "MESSDATEN SPEICHERN",
                moreInfo: "MEHR INFO ZU DEN ANALYSEN",
                analysisFor: "NITT ANALYSE FÜR: %s",
            }
        },
        suggestion: {
            heading: "NITT\nMEHR INFO ZU\nDEN ANALYSEN",
            freeAdvising: 'KOSTENLOSE\nBERATUNG',
            preventionKits: 'PRÄVENTIONS\nKITS',
            content1: 'SIE SIND OK\nBitte sorgen Sie sich weiterhin um Ihre Gesundheit.',
            content2: 'Wenn Ihr natürliches Immunsystem um -%s% geschwächt ist kann Ihr tägliches Leben durch die leichte Störungen beeinträchtigt werden kann..\n\nUm Ihre Körpertemperatur natürlich auszugleichen,  empfehlen wir Ihnen MFT-Prevention BASIC Program-Kit, ohne Chemie und Nebenwirkungen.',
            content3: 'Wenn Sie mit Hautreizungen und schneller Hautalterung (Falten, trockene Haut ...) konfrontiert sind, empfehlen wir Ihnen ein MFT-Prevention Advance Beauty Program-Kit ohne Chemikalien und Nebenwirkungen.',
            content4: 'Wenn Ihr natürliches Immunsystem um -%s% geschwächt ist, kann Ihr tägliches Leben durch fortgeschrittene Störungen beeinträchtigt werden..\n\nNach Natural Law kann die Körpertemperatur aufgrund einer biologischen Schädigung des faszialen Bindegewebes erheblich variieren. Wenn sich der schnelle Elektronendurchgang vom Körper verlangsamt, sinkt die Temperatur unter 36°C..\n\nUm Ihre Körpertemperatur natürlich auszugleichen, empfehlen wir Ihnen MFT-Prevention ADVANCED Program-Kit, ohne Chemikalien und Nebenwirkungen;',
            content5: 'Wenn Ihr natürliches Immunsystem um -%s% geschwächt ist, kann Ihr tägliches Leben durch chronische Störungen beeinträchtigt werden..\n\nNach Natural Law kann die Körpertemperatur aufgrund einer biologischen Schädigung des faszialen Bindegewebes erheblich variieren. Wenn sich der schnelle Elektronendurchgang vom Körper verlangsamt, sinkt die Temperatur unter 35°C..\n\nUm Ihre Körpertemperatur  natürlich auszugleichen,  empfehlen wir Ihnen MFT-Prevention CHRONIC Program-Kit, ohne Chemikalien und Nebenwirkungen',
        },
        placeholders: {
            email: "beispiel@gmail.com",
            password: "password",
            passwordAgain: "password wiederholen",
            phone: "Mobile Nummer",
            name: "Name",
            surname: "Nachname",
            city: "Ort-City",
        },
        freeAdvising: {
            thankYou: 'Vielen Dank für Ihre Nachricht.',
            thankYouDesc: 'Wir werden uns so bald als möglich bei Ihnen melden',
        },
        errors: {
            emailNotValid: 'Die eingegebene E-Mail-Adresse ist ungültig',
            phoneNotValid: 'Die eingegebene Telefonnummer ist ungültig',
            tryAgain: 'Bitte versuchen Sie es später noch einmal.',
            noData: 'Wir haben keine Historiedaten für das ausgewählte Familienmitglied.',
        },
        legalPoints: {
            heading: 'HAFTUNGSAUSSCHLUSS',
            content: 'Wir machen Sie darauf aufmerksam, dass die Temperaturwerte je nach Herstellergeräten und Algorithmen variieren können. Wir haben bei unseren Berechnungen bestimmte Parameter berücksichtigt, um eine angemessene Bewertung vorzunehmen.\n\nNITT ist ein Diagnosewerkzeug, das von Natural Law Scientific Systems in Zürich, Schweiz, entwickelt wurde. Es basiert auf der Tatsache, dass alle biologischen Systeme, d. h. Vegetation, Tiere und Menschen, die natürliche Immunität durch ihr selbstregulierendes Wärmesystem aufrechterhalten.\n\nDer NITT-Service erfolgt unter Ausschluss jeglicher Haftung und unter Ausschluss jeglicher Gewährleistung. Der NITT-Dienst stellt keine medizinische, psychiatrische oder psychologische Behandlung dar. Im Rahmen der angebotenen Leistungen werden keine Diagnosen gestellt und keine Heilungsversprechen gegeben. Der NITT-Service kann eine notwendige Behandlung durch einen Arzt nicht ersetzen.',
        },
        login: {
            dontHaveAccount: 'Noch kein NITT-Konto?',
            signUp: 'Anmelden!',
            forgotPassword: 'Passwort Vergessen?',
            or: 'oder',
        },
        header: {
            familyAccount: 'FAMILIEN\nKONTO',
            menu: 'MEHR',
            goBack: 'ZURÜCK',
        },
        measurePoints: {
            left: '(Links) ',
            right: '(Rechts) ',
            neck: 'Hals',
            nape: 'Nacken',
            back: 'Kreuz',
            handIn: 'Hand innen',
            handOut: 'Hand aussen',
            belly: 'Bauch',
            footUp: 'Fuss oben',
            footDown: 'Fuss unten',
            rightSide: 'Rechte Körperseite',
            leftSide: 'Linke Körperseite',
            description: 'Hier sind Ihre Messdaten aus der NITT-App.',
            ear: 'Ohr',
        },
    },
    currentLanguage: 'en',
};

const TranslateReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case "SET_TRANSLATE":
            return {...state, ...action.payload}
        case "GET_TRANSLATE":
            return state[action.payload];
        default:
            return state;
    }
}

export default TranslateReducer;
