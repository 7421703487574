import React from 'react';
import {
    Dimensions,
    Linking,
    Platform,
    ScrollView,
    Text,
    View
} from "react-native";
import Button from "../components/Button";
import Colors from "../../res/constants/Colors";
import Header from '../components/Header';
import LanguageChangeButton from "../components/HeaderButtons/LanguageChangeButton";
import { connect } from 'react-redux';
import Space from "../components/Space";
import 'firebase/auth';
import {setUser} from "../redux/actions/UserActions";
import HeaderLogo from "../components/HeaderButtons/HeaderLogo";
import AutoHeightImage from "react-native-auto-height-image";
import MenuBackButton from "../components/HeaderButtons/MenuBackButton";

const platformSpecific = Platform.select({
    ios: {
        containerPadding: 10,
    },
    android: {
        containerPadding: 25,
    },
    web: {
        containerPadding: 30,
    },
});

function productSuggestion(props) {
    const translations = props.translate[props.translate.currentLanguage];
    const overallImmunity = props.route.params.overallImmunity;
    const imageWidth = Dimensions.get('window').width - 70;
    const imageHeight = imageWidth * 0.667;

    return (
        <ScrollView contentContainerStyle={styles.wrapper}>
            <Header
                headerLeft={() => (<MenuBackButton realBack={true} />)}
                headerCenter={() => (<HeaderLogo />)}
                headerRight={() => (<LanguageChangeButton />)}
            />
            <Space />
            <Text style={styles.screenHeading}>{translations.suggestion.heading}</Text>
            <Space />
            <AutoHeightImage style={{width: imageWidth, height: imageHeight}} source={require('../../res/images/kits/pet-ir-kit.png')} width={imageWidth} />
            <AutoHeightImage style={{width: imageWidth, height: imageHeight}} source={require('../../res/images/kits/pet-uv-kit.png')} width={imageWidth} />
            {props.translate.currentLanguage === 'tr' ?
                <Text style={styles.content}>
                    Evcil Hayvanınızın doğal bağışıklık sistemi -% 30'un altındaysa, vücut ısısını yeniden
                    dengelemenizi ve hasarlı burun mukozasını onarmanızı öneririz. Zedelenmis Mukoza bag dokusu viral
                    enfeksiyonlara, eklem ağrılarına ve diğer rahatsızlıklara yol acar.
                    <Space/>
                    Evde yaşayan köpekler ve kediler, özellikle bağ mukozası dokusu gibi fasyal zedelenmelere karşı
                    hassastır. MFT-ANIMALS önleme programında, evcil hayvanların vücudundaki hızlı elektron akışını
                    böylece hızlandırıp ısı düzenlemesini optimal bir sekilde destekliyoruz. NATURAL LAW Science’a göre
                    viral enfeksiyonlar vücutta böylece çoğalamaz.
                </Text>
                : props.translate.currentLanguage === 'en' ?
                    <Text style={styles.content}>
                        If your PETS natural immune system is below -30%, we recommend you to rebalance the body temperature
                        and repair injured nasal mucous membranes. This prevents from viral infections and joint pains as
                        well as other disorders.
                        <Space/>
                        Dogs and cats that live in the household are particularly susceptible to fascial injuries, such as
                        the connective mucous tissue. In the ANIMAL prevention program we support the rapid flow of
                        electrons in the Pets body and support the heat regulation for natural immunity, so that viral
                        infections cannot multiply in a body.
                    </Text>
                    :
                    <Text style={styles.content}>
                        Wenn das natürliche PETS-Immunsystem unter -30% liegt, empfehlen wir Ihnen, die Körpertemperatur auszugleichen, indem bei Ihrem PET die verletzten Nasenschleimhäute regeneriert werden. Dies verhindert Virusinfektionen, Gelenkschmerzen sowie andere Störungen.
                        <Space/>
                        Hunde und Katzen, die im Haushalt leben, sind besonders anfällig für Faszienverletzungen auf das Bindeschleimhautgewebe. Im MFT-ANIMAL-Präventionsprogramm unterstützen wir den schnellen Elektronenuss im Körper von Haustieren und somit unterstützen die optimale Wärmeregulierung im Körper. Nach NATURAL LAW SCIENCE, können sich dadurch Virusinfektionen in einem Körper nicht vermehren.
                    </Text>
            }
            <View style={styles.buttonsWrapper}>
                <Button label={translations.suggestion.freeAdvising} wrapperStyle={styles.button} style={{fontSize: 13,}} onPress={() => {
                    props.navigation.push('FreeAdvising');
                }} />
                <Button label={translations.suggestion.preventionKits} wrapperStyle={styles.button} style={{fontSize: 13,}} onPress={() => {
                    Linking.openURL('https://chaava.com/shop_en/animals/?catalog/all/-/price/1');
                }} />
            </View>
        </ScrollView>
    );
}

const mapStateToProps = (state) => {
    return {
        translate: state.TranslateReducer,
        user: state.UserReducer,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        setUser: payload => dispatch(setUser(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(productSuggestion);

const styles = {
    wrapper: {
        alignItems: 'center',
        backgroundColor: Colors.mainBackground,
        minHeight: Dimensions.get('window').height,
    },
    container: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: platformSpecific.containerPadding,
        width: 100 + '%',
    },
    screenHeading: {
        fontSize: 24,
        fontFamily: 'Montserrat_800ExtraBold',
        color: '#FFF',
        textAlign: 'center',
    },
    content: {
        fontSize: 18,
        fontFamily: 'Montserrat_400Regular',
        color: '#FFFFFF',
        paddingHorizontal: 20,
        width: 100 + '%',
    },
    buttonsWrapper: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        width: 90 + '%',
        marginBottom: 20,
        marginTop: 10,
    },
    button: {
        width: 49 + '%',
    },
};
