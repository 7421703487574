import React from 'react';
import AutoHeightImage from 'react-native-auto-height-image';
import { Dimensions, TouchableOpacity } from 'react-native';
import { navigate } from '../../RootNavigation';
import {setMenu} from "../../redux/actions/MenuActions";
import {connect} from "react-redux";

const width = Dimensions.get('window').width > 800 ? 100 : (Dimensions.get('window').width / 3) - 25;

function HeaderLogo(props) {
    return (
        <TouchableOpacity
            onPress={() => {
                props.setMenu({showMenu: false});
                navigate('Landing');
            }}
            style={{
                width: width,
            }}
        >
            <AutoHeightImage source={require('../../../res/images/logo.png')} width={width} style={{
                position: 'absolute',
                left: 50 + '%',
                marginLeft: width / 2 * -1,
                top: -20,
                width: width,
                height: width * 1.09,
            }} />
        </TouchableOpacity>
    );
}

const mapStateToProps = state => {
    return {
        menuState: state.MenuReducer,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setMenu: (payload) => dispatch(setMenu(payload)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(HeaderLogo);
